import React, { useEffect, useRef, useState } from 'react';

import useStyles from './useStyles';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';

import botImg from "../../../../icons/bot.png"
import { useAuth } from 'firebase/AuthContext';


const HRAccountSection = ({setSelectedView, selectedView, orgId}) => {

  const { signOut, currentUser } = useAuth();

  function processImageUrl(url) {
    if (!url) return botImg;
    return url.replace(/=s96-c$/, "=s400-c");
  }
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.profilePicContainer}>
        <img src={processImageUrl(currentUser.photoURL)} className={classes.profilePic} />
      </div>
      <div className={classes.name}>{currentUser.displayName}</div>
      <div className={classes.name}>{orgId}</div>
      <div className={classes.logoutDiv} 
        style={{
          backgroundColor: selectedView=="candidate"?'whitesmoke':"none"
        }}
      onClick={()=>setSelectedView("candidate")}>
        <div className={classes.logoutButton} >Candidates</div>
      </div>

      {/* <div className={classes.logoutDiv} onClick={()=>setSelectedView("feedback")}>
        <div className={classes.logoutButton} >Common Feedback</div>
      </div> */}

      <div className={classes.logoutDiv} style={{marginTop:"10%"}} onClick={signOut}>
        <LogoutIcon style={{ color: "#858585" }} fontSize='medium' />
        <div className={classes.logoutButton} >Logout</div>
      </div>
    </div>
  );
}

export default HRAccountSection;
