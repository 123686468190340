import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    passedIcon: {
        color: theme.palette.success.main,
        marginRight: theme.spacing(1),
    },
    failedIcon: {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
    },
    testCaseStatus: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width:200
    },
    statusText: {
        fontWeight: 'bold',
    },
}));

export default useStyles;
