/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { Button } from "../Button";

export const KeyFeature = ({ text, img }) => {
  const redirectToLogin = () => {
    window.location.href = "/login";
  }
  return (
    <div className="div-1-kf" onClick={redirectToLogin} style={{cursor:"pointer"}}>
      <img src={img} style={{
        objectFit: "cover",
        objectPosition: "0 20%", 
        height: "60%",
        width: "100%",
        borderRadius: "10px",
      }}></img>
      <div className="text-kf">
        {text}
      </div>
      {/* <Button className="button-instance" size="normal" text="Try DS Algo Interviews for Free!" type="primary" handleClick={redirectToLogin} /> */}
    </div>
  );
};

KeyFeature.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["large", "normal", "small"]),
  text: PropTypes.string,
};
