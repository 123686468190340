import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    marginBottom: "2%",
    backgroundColor: "white",
    flexDirection: "column",
    padding: "2%",
    paddingInline: "2.5%",
    borderRadius: "5px",
  },
  heading:{
    fontWeight: "500",
    fontSize: "1.5rem",
    marginBottom: "1%",
  },
  metaDiv:{
  },
  metaId:{
    marginBottom: "4%",
  },
  contentDiv:{
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1%",
    color:"#858585",
    fontWeight: "500",
  },
  statusDiv:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "3%",
  },
  statusTitle:{
    textDecoration: "underline",
  }
}));

export default useStyles;
