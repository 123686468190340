import React, { useEffect, useRef, useState } from 'react';

import useStyles from './useStyles';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import config from 'config';
import { useStore } from 'store';
import { LinearProgress } from '@material-ui/core';


const CodeRunner = ({ code }) => {

  const [codeRunning, setCodeRunning] = useState(false);
  const [runnerResponse, setRunnerResponse] = useState({
    output: "",
    time: "",
  });
  const [openRunnerOutput, setOpenRunnerOutput] = useState(false);

  const {
    state: { editor: { selectedLanguageId, options }, monacoTheme, baseURLArkajit, baseURL },
    actions: { editor: { setSelectedLanguageId, setOptions, setMonacoTheme }, showNotification },
    effects: { defineTheme }
  } = useStore();


  function runCode() {

    setRunnerResponse(
     {
      output:"",
      time:""
     }
    )
    console.log("Running code");
    setCodeRunning(true);
    let backendLanguageId = Number(config.findBackendLanguageId(selectedLanguageId));

    // Simulate running test cases
    const data = {
      question_id: "",
      langauge_id: String(backendLanguageId),
      code: code,
    }
    // {
    //   "status": "Success",
    //     "message": "question_fetched",
    //       "result": {
    //     "stdout": "Hello World!",
    //       "time": "0.003",
    //         "memory": 848,
    //           "stderr": null,
    //             "token": "e87d8408-a84a-4f99-a2df-94c6757775cb",
    //               "compile_output": null,
    //                 "message": null,
    //                   "status": {
    //       "id": 3,
    //         "description": "Accepted"
    //     }
    //   }
    // }

    fetch(baseURLArkajit + '/code-runner/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data)
        if (data.result?.stdout && data.result?.time) {
          setRunnerResponse({
            output: data.result.stdout,
            time: data.result.time,
          });
        }else if(data.result?.compile_output){
          setRunnerResponse({
            output: data.result.compile_output,
            time: data.result.time,
          });
        }else if(data.result?.stderr){
          setRunnerResponse({
            output: data.result.stderr,
            time: data.result.time,
          });
        }
        else if(data.result?.time)
        {
          setRunnerResponse({
            output: "",
            time:data.result.time,
          })
        }
        setOpenRunnerOutput(true);
        setCodeRunning(false);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });


    // setTimeout(() => {
    //   setCodeRunning(true);
    //   setRunnerResponse({
    //     output: "Hello World",
    //     time: "134 ms",
    //   });
    //   setCodeRunning(false);
    //   setOpenRunnerOutput(true);
    // }, 2000);
  }

  const handleChange = (event, isExpanded) => {
    if (openRunnerOutput && !isExpanded) {
      setOpenRunnerOutput(false);
    } else if (isExpanded && runnerResponse.output !== "") {
      setOpenRunnerOutput(!openRunnerOutput);
    }
  }

  const classes = useStyles();

  return (
    <div>
      <Accordion style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        rotate: '180deg',
        width: "45%",
        cursor: "auto",
        boxShadow: "none",
        paddingInline: "1%",
        borderWidth: "0px 0px 1px 0px",
        borderStyle: "solid",
        borderRadius: "0px",
        borderColor: "#DCDCDB"
      }}
        expanded={openRunnerOutput}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            padding: 0,
          }}
          children={
            <div className={classes.outputConsole}>

              <div className={classes.summary}>
                Console
                {runnerResponse.time && runnerResponse.time != "" && <div className={classes.ranIn}>
                  Ran in &nbsp; <div className={classes.ranInMS}>
                    {runnerResponse.time}
                  </div>
                </div>
                }
                <button
                  className={codeRunning ? classes.runButton : classes.runButtonBlue}
                  onClick={runCode}>
                  {codeRunning &&
                    <CircularProgress size={"20px"} classes={{ root: classes.linearLoaderRoot }} />
                  }
                  {!codeRunning && <>Run Code</>
                  }
                </button>
              </div>
            </div>
          }
        >
        </AccordionSummary>
        <AccordionDetails>

          <div className={classes.output}>
            {runnerResponse.output}
          </div>

        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CodeRunner;
