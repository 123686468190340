const setIsEditorReady = ({ state }, isReady) => {
  state.isEditorReady = isReady;
};

const setThemeMode = ({ state }, themeMode) => {
  state.themeMode = themeMode;
  localStorage.setItem('themeMode', themeMode); // Current solution is temporary
  // TODO: Design proper solution
  // to sync state (or a field of state) with localStorage
};

const setEditorMode = ({ state }, editorMode) => {
  state.editorMode = editorMode;
};

const setInterviewState = ({ state }, interviewState) => {
  state.interviewState = interviewState;
};

const setInterviewId = ({ state }, interviewId) => {
  state.interviewId = interviewId;
};
const setCodingRoundStarted = ({ state }, bool) => {
  state.codingRoundStarted = bool;
};
const setCodingRoundQuestion = ({ state }, val) => {
  localStorage.setItem('codingRoundQuestion', JSON.stringify(val));
  state.codingRoundQuestion = val;
};

const setCandidateResumeCheckBox = ({ state }, bool) => {
  state.candidateResumeCheckBox = bool;
};
const showNotification = ({ state }, { message, variant = 'info', opt = {} }) => {
  state.notifications = {
    isActive: true,
    message,
    variant,
    opt,
  }; // now designed for only one message
};

const hideNotification = ({ state }) => {
  state.notifications.isActive = false;
};

const setSelectedLanguageId = type => ({ state }, id) => {
  state[type].selectedLanguageId = id;
};

const setTestCaseResults = (results) => ({ state }) => {
  state.testCaseresults = results;
};

const setCodingRoundVideoBlobURL = (url) => ({ state }) => {
  state.codingRoundVideoBlobURL = url;
}

const setCodingRoundScreenBlobURL = (recorder) => ({ state }) => {
  state.codingRoundScreenBlobURL = recorder;
}

const editor = {
  setSelectedLanguageId: setSelectedLanguageId('editor'),
  setOptions({ state }, options) {
    state.editor.options = options;
  },
  setMonacoTheme({ state }, theme) {
    state.monacoTheme = theme;
  },
  setTestCaseResults: setTestCaseResults('editor'),
};

const diffEditor = {
  setSelectedLanguageId: setSelectedLanguageId('diffEditor'),
};

export {
  hideNotification,
  setThemeMode,
  setEditorMode,
  setIsEditorReady,
  showNotification,
  setInterviewState,
  setInterviewId,
  setCodingRoundStarted,
  setCandidateResumeCheckBox,
  setCodingRoundQuestion,
  setCodingRoundScreenBlobURL,
  setCodingRoundVideoBlobURL,
  editor, diffEditor,
};
