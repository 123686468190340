import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import useStyles from './useStyles';
import { useAuth } from 'firebase/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useStore } from 'store';


export default function SignUp() {

    const { signInWithGoogle, signUp } = useAuth();
    const Navigate = useNavigate();
    const email = useRef();
    const password = useRef();
    const rePassword = useRef();
    const classes = useStyles();

    const [validation, setValidation] = useState(null);

    const {
        state: { baseURLArkajit },
    } = useStore();

    const submitAction = async (e) => {
        setValidation(null);
        e.preventDefault();

        if (email.current.value === "") {
            setValidation('Enter your email address')
        }
        else if (password.current.value === "") {
            setValidation('Enter your password')
        }
        else if (password.current.value !== rePassword.current.value) {
            setValidation('Passwords do not match')
        }
        else {
            signUp(email.current.value, password.current.value)
                .then(() => { Navigate('/login') })
                .catch((err) => { setValidation(err.message) })

        }
    }

    const googleSignIn = async () => {

        signInWithGoogle()
            .then((data) => {
                console.log("Google Sign In Successful", data)
                fetch(baseURLArkajit+'/candidate_flow/login', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        { 
                            user_id: data.user?.uid,
                            user_name: data.user?.displayName,
                            email: data.user?.email,
                          }),
                  })
                    // .then((data) => {
                    //     console.log(data)
                    //     Navigate('/candidate/dashboard')
                    // })
                    .catch(error => console.error(error));
            })
            .then((data) => {
                console.log(data)
                Navigate('/candidate/dashboard')
            })
            .catch((err) => {
                setValidation(err.message)
            })

    }

    return (
        <div className={classes.fullpage}>
            {validation && <p
                style={{
                    textAlign: 'center',
                }}
            >{validation}</p>}
            <div className={classes.blob1}>
                <img alt='Blob1' width='500px' height='500px' src='./Rectangle4.png' />
            </div>
            <div className={classes.blob2}>
                <img alt='Blob2' width='700px' height='700px' src='./Ellipse2.png' />
            </div>
            <div className={classes.blob3}>
                <img alt='Blob3' width='300px' height='400px' src='./Ellipse4.png' />
            </div>
            <form onSubmit={submitAction} className={classes.form}>
                <p className={classes.starttext} >Hi There!</p>
                <h1 className={classes.heading}>Let's get you started</h1>
                <input ref={email} type='email' className={classes.input} placeholder='Email' />
                <input ref={password} type='password' className={classes.input} placeholder='Password' />
                <input ref={rePassword} type='password' className={classes.inputbottom} placeholder='Confirm Password' />
                <button className={classes.submit}>Sign Up</button>
                <div className={classes.line}>
                    <span className={classes.span}>
                        OR
                    </span>
                </div>
                <p className={classes.subheading}>Sign In With</p>
                <img
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={googleSignIn}
                    className={classes.icon} alt='Google Icon' width={"50"} height={"50"} src='/icons/GoogleIcon.png' />
                <div className={classes.bottom}>
                    <p>Already a member?&nbsp;</p>
                    <Link
                        style={{
                            color: '#0F70EB',
                        }}
                        to='/login'>
                        Login
                    </Link>
                </div>
            </form>
        </div>
    )
}
