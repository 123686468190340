import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontFamily: "'Kumbh Sans', sans-serif",
    width:'100%',
  },
  rightDiv: {
    width:'75%',
    paddingInline:"4%",
  },
}));

export default useStyles;
