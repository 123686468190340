import React, { useContext, useState } from 'react'
import { createContext } from 'react'
import { auth } from './firebase';
import { useEffect } from 'react';
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth';
import Loading from 'components/Loading';


const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const provider = new GoogleAuthProvider();

    const signUp = (email, password) =>{
        return createUserWithEmailAndPassword(auth, email, password);
    }

    const signIn = (email, password) =>{
        return signInWithEmailAndPassword(auth, email, password);
    }

    const signInWithGoogle = () => {
        return signInWithPopup(auth, provider)
    }

    const signOut = () =>{
        return auth.signOut();
    }

    const resetPassword = (email) =>
    {
        return sendPasswordResetEmail(auth, email);
    }

    const value = {
        currentUser,
        signUp,
        signIn,
        signInWithGoogle,
        signOut,
        resetPassword,
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user =>{
            setCurrentUser(user);
            console.log(user);
            setIsLoading(false);
        })
      return unsubscribe;
    }, [])
    

    return (
        <AuthContext.Provider value={value}>
            {!isLoading ? children : <Loading/>}
        </AuthContext.Provider>
    )
}
