import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const getTheme = themeMode => {

  const palette = {
    type: themeMode,
    primaryBlue: '#0f70eb',
    primaryGrey: '#a9a9a9',
    secondaryGrey: '#f0f0f0',
    background: '#F9F9F9',
    text: '#333',
  };

  if (themeMode === 'dark') {
    palette.background = {
      paper: '#202124',
    };
  }

  return createMuiTheme({ palette });
};

export default getTheme;
