import React, { useEffect, useRef, useState } from 'react';

import useStyles from './useStyles';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';

import botImg from "../../../../icons/bot.png"
import { useAuth } from 'firebase/AuthContext';
import { UserRole } from 'utils/enums/UserRole';


const DashboardAccount = ({photo_url,user_name, email}) => {

  const { signOut } = useAuth();

  function processImageUrl(url) {
    if (!url) return botImg;
    return url.replace(/=s96-c$/, "=s400-c");
  }
  const classes = useStyles();
  const [userRole, setUserRole] = useState(UserRole.CANDIDATE);

  useEffect(() => {
    if (localStorage.getItem('user_role')) setUserRole(localStorage.getItem('user_role'));
  });

  return (
    <div className={classes.root}>
      <div className={classes.profilePicContainer}>
        <img src={processImageUrl(photo_url)} className={classes.profilePic} />
      </div>
      <div className={classes.name}>{user_name}</div>
      {
        (userRole == UserRole.CANDIDATE) &&
        <div className={classes.logoutDiv} onClick={signOut}>
          <LogoutIcon style={{ color: "#858585" }} fontSize='medium' />
          <div className={classes.logoutButton} >Logout</div>
        </div>
      }
    </div>
  );
}

export default DashboardAccount;
