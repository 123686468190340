import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    fullpage: {
        // fontFamily: "'Manrope', sans-serif",
        height: `96vh`,
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(243, 244, 246, 1)',
    },
    starttext:{
        color:'#0F70EB',
        fontSize:'22px',
        margin:0,
        textAlign:'center',
        fontFamily: "'Kumbh Sans', sans-serif", 
    },
    blob1:{
        opacity:0.7,
        position:'absolute',
        height:'30px',
        width:'30px',
        top:'40vh',
        left:'16vw',

    },
    blob2:{
        opacity:0.5,
        position:'absolute',
        // height:'30px',
        // width:'30px',
        top:'0',
        right:'20vw',
        
    },
    blob3:{
        position:'absolute',
        opacity:0.8,
        height:'30px',
        width:'30px',
        top:'10vh',
        left:'0vw',
    },
    form: {
        minWidth:"300px",
        width:'32%',
        zIndex:'1',
        padding:'5%',
        paddingInline:'6%',
        rowGap:'6px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius:'10px',
    },
    bottom: {
        fontSize:'small',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input:{
        '&:focus':{
            border:'none',
            outline: "1px solid black",
        },
        '&::placeholder':{ 
            color:"rgba(192, 192, 192, 1)",
            fontStyle:'normal',
        },
        padding: '8px',
        paddingInline: '14px',
        marginTop: '20px',
        marginBottom: '20px',
        width:'80%',
        borderRadius: '3px',
        borderColor:'rgba(208, 208, 208, 0.5)',
        borderWidth: '1px',
        fontFamily: "'Kumbh Sans', sans-serif",
    },
    submit:{
        width:'80%',
        marginBottom:'10px',
        padding: '8px',
        borderWidth: '1px',
        borderRadius: '3px',
        borderColor:'transparent',
        backgroundColor:'#0F70EB',
        color:"white",
        fontFamily: "'Kumbh Sans', sans-serif",
    },
}));

export default useStyles;
