import React from 'react'
import { useRef } from 'react';
import useStyles from './useStyles';
import { useAuth } from 'firebase/AuthContext';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function Forgotpassword() {

  const { resetPassword } = useAuth();
  const [validation, setValidation] = useState(null)

  const submitAction = (e) => {

    setValidation(null)
    e.preventDefault();

    if (email.current.value === '') {
      setValidation('Please enter a valid email')
    }
    else {
      resetPassword(email.current.value)
        .then((msg) => { console.log(msg) })
        .catch(err => { setValidation(err.message) })
    }


  }

  const email = useRef();

  const classes = useStyles();

  return (
    <div className={classes.fullpage}>
      {validation && <p
        style={{
          textAlign: 'center',
        }}
      >{validation}</p>}
      <div className={classes.blob1}>
        <img alt='Blob1' width='500px' height='500px' src='./Rectangle4.png' />
      </div>
      <div className={classes.blob2}>
        <img alt='Blob2' width='400px' height='400px' src='./Ellipse2.png' />
      </div>
      <div className={classes.blob3}>
        <img alt='Blob3' width='300px' height='400px' src='./Ellipse4.png' />
      </div>
      <form onSubmit={submitAction} className={classes.form}>
        <p className={classes.starttext} >Forgot password?</p>
        <h1 style={{
          marginTop: '0',
          textAlign: 'center',
        }}>Enter your Email address</h1>

        <p className={classes.starttext} >We’ll Send You a Link To reset
          Your Password</p>
        <input ref={email} className={classes.input} placeholder='Email' />

        <button className={classes.submit}>Get Link</button>

      </form>
    </div>
  )
}

export default Forgotpassword;