import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import Hamburger from 'hamburger-react'
import { Button } from "../Button";

export const MobileNav = () => {
  const [sidebar, setSidebar] = React.useState(false);

  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const redirectToLogin = () => {
    window.location.href = "/login";
  }
  return (
    <div className="mobileNav">
      <div className="closeIcon">
        <Hamburger toggled={sidebar} toggle={setSidebar} size={28} />
      </div>
      {sidebar &&
        <div className="sideBar">
          <Button className="button-instance" size="normal" text="Try Now!" type="primary" handleClick={redirectToLogin} />
          <div className="text-wrapper-26"><a href="#home" onClick={(event) => handleLinkClick(event, "home")}>Home</a></div>
          <div className="text-wrapper-26"><a href="#aboutUs" onClick={(event) => handleLinkClick(event, "aboutUs")}>About Us</a></div>
          <div className="text-wrapper-26"><a href="#contactUs" onClick={(event) => handleLinkClick(event, "contactUs")}>Contact</a></div>
        </div>
      }
    </div>
  )
};

MobileNav.propTypes = {
  size: PropTypes.oneOf(["primary", "large"]),
  text: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
