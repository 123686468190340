import { createTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const chipTheme = createTheme({
  components: {
    // Name of the component
    MuiChip: {
      styleOverrides: {
        // Name of the slot
        colorPrimary: {
          // Some CSS
          backgroundColor: "#e3fae8",
          color: "#0b692c",
          fontWeight: "600",
          fontSize: "0.9rem",
        },
        colorWarning: {
          // Some CSS
          backgroundColor: "#fce6c2",
          color: "#e88249",
          fontWeight: "600",
          fontSize: "0.9rem",
        },
        colorError: {
            // Some CSS
            backgroundColor: "#FAA0A0",
            color: "#880808",
            fontWeight: "600",
            fontSize: "0.9rem",
        },
      },
    },
  },
});
export {
  chipTheme
}