import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    width:'28%',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
  profilePic: {
    width: '12rem',
    height: '12rem',
    borderRadius: '50%',
  },
  name: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
  },
  logoutDiv:{
    display: 'flex',
    color: '#858585',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '0.8rem',
    '&:hover': {
      backgroundColor: 'whitesmoke',
      cursor: 'pointer',
    },
  },
  logoutButton: {
    marginInline: '0.5rem',
    fontWeight: '500',
    fontSize: '1.2rem',
  },
}));

export default useStyles;