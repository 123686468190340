import React from 'react';

import ErrorBoundary from 'react-error-boundary';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import Layout from 'layout';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'firebase/AuthContext';

const App = _ => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <BrowserRouter>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

export default App;
