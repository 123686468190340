import React from 'react';

import HeaderLoader from 'sections/Header';
import { asyncComponentLoader } from 'utils';
import { useLocation } from 'react-router-dom';

import useStyles from './useStyles';
import logo from "../../icons/logo.png"



const PhoneLayout = _ => {
  const classes = useStyles();
  const location = useLocation();
  const currentRoute = location.pathname;
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "2rem",
      height: "100vh",
      textAlign: "center",
      flexDirection: "column",
    }}>
      

      Please use desktop for the best experience

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10rem",
          fontSize: "1.5rem",
        }}
      ><img
        style={{ marginInline: "1rem" }}
      src={logo} height={"50px"} width={"50px"}></img>
      thehiringcompany.ai</div>
    </div>
  );
};

export default PhoneLayout;
