const config = {
  links: {
    github: 'https://github.com/SurenAt93/monaco-react',
  },

  notifications: {
    props: {
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 2500,
    },
    defaultState: {
      isActive: false,
      message: '',
      variant: 'info',
      opt: {},
    },
  },

  messages: {
    errorBoundary: {
      somethingWentWrong: 'Oooops... Sorry, I guess, something went wrong. You can:',
      resetApp: 'Press here to reset the application',
      openIssue: 'Press here to open issue about it on GitHub',
    },
    invalidOptions: 'Seems like options file is not a valid json, please double check it.'
  },

  urls: {
    octocatIcon: '/icons/octocat.png',
    themeModeIcon: '/icons/theme-mode.png',
    themeModeIcon: '/icons/bot.png',
    IEditorOptions: 'https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IEditorOptions.html#acceptSuggestionOnCommitCharacter',
  },

  defaultThemes: ['vs-dark', 'light'],
  backendSupportedLanguages : [
    {
      "id": 45,
      "name": "Assembly (NASM 2.14.02)"
    },
    {
      "id": 46,
      "name": "Bash (5.0.0)"
    },
    {
      "id": 47,
      "name": "Basic (FBC 1.07.1)"
    },
    {
      "id": 50,
      "name": "c"
    },
    {
      "id": 54,
      "name": "cpp"
    },
    {
      "id": 86,
      "name": "Clojure (1.10.1)"
    },
    {
      "id": 51,
      "name": "C# (Mono 6.6.0.161)"
    },
    {
      "id": 77,
      "name": "COBOL (GnuCOBOL 2.2)"
    },
    {
      "id": 55,
      "name": "Common Lisp (SBCL 2.0.0)"
    },
    {
      "id": 56,
      "name": "D (DMD 2.089.1)"
    },
    {
      "id": 57,
      "name": "Elixir (1.9.4)"
    },
    {
      "id": 58,
      "name": "Erlang (OTP 22.2)"
    },
    {
      "id": 44,
      "name": "Executable"
    },
    {
      "id": 87,
      "name": "F# (.NET Core SDK 3.1.202)"
    },
    {
      "id": 59,
      "name": "Fortran (GFortran 9.2.0)"
    },
    {
      "id": 60,
      "name": "go"
    },
    {
      "id": 88,
      "name": "Groovy (3.0.3)"
    },
    {
      "id": 61,
      "name": "Haskell (GHC 8.8.1)"
    },
    {
      "id": 62,
      "name": "java"
    },
    {
      "id": 63,
      "name": "javascript"
    },
    {
      "id": 78,
      "name": "Kotlin (1.3.70)"
    },
    {
      "id": 64,
      "name": "Lua (5.3.5)"
    },
    {
      "id": 89,
      "name": "Multi-file program"
    },
    {
      "id": 79,
      "name": "Objective-C (Clang 7.0.1)"
    },
    {
      "id": 65,
      "name": "OCaml (4.09.0)"
    },
    {
      "id": 66,
      "name": "Octave (5.1.0)"
    },
    {
      "id": 67,
      "name": "Pascal (FPC 3.0.4)"
    },
    {
      "id": 85,
      "name": "Perl (5.28.1)"
    },
    {
      "id": 68,
      "name": "PHP (7.4.1)"
    },
    {
      "id": 43,
      "name": "Plain Text"
    },
    {
      "id": 69,
      "name": "Prolog (GNU Prolog 1.4.5)"
    },
    {
      "id": 71,
      "name": "python"
    },
    {
      "id": 80,
      "name": "R (4.0.0)"
    },
    {
      "id": 72,
      "name": "Ruby (2.7.0)"
    },
    {
      "id": 73,
      "name": "Rust (1.40.0)"
    },
    {
      "id": 81,
      "name": "Scala (2.13.2)"
    },
    {
      "id": 82,
      "name": "SQL (SQLite 3.27.2)"
    },
    {
      "id": 83,
      "name": "Swift (5.2.3)"
    },
    {
      "id": 74,
      "name": "TypeScript (3.7.4)"
    },
    {
      "id": 84,
      "name": "Visual Basic.Net (vbnc 0.0.0.5943)"
    }
  ],
  supportedLanguages: [{ id: 1, name: 'apex' }, { id: 2, name: 'azcli' }, { id: 3, name: 'bat' }, { id: 4, name: 'c' }, { id: 5, name: 'clojure' }, { id: 6, name: 'coffeescript' }, { id: 7, name: 'cpp' }, { id: 8, name: 'csharp' }, { id: 9, name: 'csp' }, { id: 10, name: 'css' }, { id: 11, name: 'dockerfile' }, { id: 12, name: 'fsharp' }, { id: 13, name: 'go' }, { id: 14, name: 'graphql' }, { id: 15, name: 'handlebars' }, { id: 16, name: 'html' }, { id: 17, name: 'ini' }, { id: 18, name: 'java' }, { id: 19, name: 'javascript' }, { id: 20, name: 'json' }, { id: 21, name: 'kotlin' }, { id: 22, name: 'less' }, { id: 23, name: 'lua' }, { id: 24, name: 'markdown' }, { id: 25, name: 'msdax' }, { id: 26, name: 'mysql' }, { id: 27, name: 'objective-c' }, { id: 28, name: 'pascal' }, { id: 29, name: 'perl' }, { id: 30, name: 'pgsql' }, { id: 31, name: 'php' }, { id: 32, name: 'plaintext' }, { id: 33, name: 'postiats' }, { id: 34, name: 'powerquery' }, { id: 35, name: 'powershell' }, { id: 36, name: 'pug' }, { id: 37, name: 'python' }, { id: 38, name: 'r' }, { id: 39, name: 'razor' }, { id: 40, name: 'redis' }, { id: 41, name: 'redshift' }, { id: 42, name: 'ruby' }, { id: 43, name: 'rust' }, { id: 44, name: 'sb' }, { id: 45, name: 'scheme' }, { id: 46, name: 'scss' }, { id: 47, name: 'shell' }, { id: 48, name: 'sol' }, { id: 49, name: 'sql' }, { id: 50, name: 'st' }, { id: 51, name: 'swift' }, { id: 52, name: 'tcl' }, { id: 53, name: 'typescript' }, { id: 54, name: 'vb' }, { id: 55, name: 'xml' }, { id: 56, name: 'yaml' }],

  findBackendLanguageId: (language_id)=>{
    let languageName = config.supportedLanguages.find(({ id }) =>{
      return id === (language_id)
    }).name;
    return config.backendSupportedLanguages.find(({ name }) => name == languageName).id;
  }

};

export default config;
