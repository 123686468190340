import React from 'react';

import HeaderLoader from 'sections/Header';
import { asyncComponentLoader } from 'utils';
import { useLocation } from 'react-router-dom';

import useStyles from './useStyles';

const HeaderSection = asyncComponentLoader(HeaderLoader);


const Header = _ => {
  const classes = useStyles();
  const location = useLocation();
  const currentRoute = location.pathname;
  return (
    <div className={classes.root}>
      {currentRoute === "/" ? null : <HeaderSection />}
    </div>
  );
};

export default Header;
