import React, { useEffect, useRef, useState } from 'react';

import useStyles from './useStyles';
import { MoonLoader } from 'react-spinners';

const LoadingScreen = ({ loadingText, action, apiCall }) => {

  const classes = useStyles();
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    if (action) {
      setTimeout(() => {
        action();
      }, 5000);
    }

    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % loadingText.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes.root}>
      {/* <MoonLoader size={100} color="#0F70EB" /> */}
      <div className={classes.loadingText}>
        {loadingText[textIndex]}
        <div className={classes.loadingDots}>
          <div className={classes.dot}></div>
          <div className={classes.dot}></div>
          <div className={classes.dot}></div>
        </div>
      </div>

    </div>
  );
}

export default LoadingScreen;
