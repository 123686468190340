import React, { useEffect, useState } from 'react';

import MonacoEditor from '@monaco-editor/react';
import Settings from './Settings';

import SendIcon from '@material-ui/icons/Send';


import { useStore } from 'store';
import { isMobile } from 'utils';
import examples from 'config/examples';
import config from 'config';
import monacoThemes from 'monaco-themes/themes/themelist';

import useStyles from './useStyles';
import {  MenuItem, TextField, Typography } from '@material-ui/core';
import ChatSection from './ChatSection/ChatSection';
import CodeRunner from 'components/CodeRunner';
import LoadingScreen from 'components/LoadingScreen';
import { useAuth } from 'firebase/AuthContext';
import RecordRTC, { DiskStorage, bytesToSize } from 'recordrtc';
import { useNavigate } from 'react-router-dom';


const Editor = () => {
  const classes = useStyles();

  const [chat, setChat] = useState([
    {
      "text": "Hey there! Whats up?",
      "isUser": false
    }
  ]);

  const [screenRecorder, setScreenRecorder] = useState(null);
  const [videoRecorder, setVideoRecorder] = useState(null);

  const {
    state: {
      editor: { selectedLanguageId, options },
      monacoTheme, baseURLArkajit, candidateResumeCheckBox, codingRoundQuestion, codingRoundStarted, interviewId
    },
    actions: { editor: { setSelectedLanguageId }, setInterviewId, setCodingRoundStarted },
  } = useStore();

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const axios = require("axios").default;


  //to do use useStore to get the test case results
  const [interviewConfigured, setInterviewConfigured] = useState(false);
  const [screenRecordPermission, setScreenRecordPermission] = useState("init");
  const [videoAudioPermission, setVideoAudioPermission] = useState("init");
  const [nativeStreams, setNativeStreams] = useState([]);
  const [codingQuestion, setCodingQuestion] = useState("");


  function handleLanguageChange(ev) {
    setSelectedLanguageId(ev.target.value);
  }

  const handleBeforeUnload = (event) => {
    if(interviewId!=null && interviewId!=""){
      fetch(baseURLArkajit + '/agent_feedback/end', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            "interview_id": interviewId,
          }),
      })
      .catch(error => console.error(error));
    }
  };

  useEffect(() => {
    let codingQuestionId;
    if (!codingRoundQuestion) {
      let l = JSON.parse(localStorage.getItem('codingRoundQuestion'));
      codingQuestionId = l ? l.question_id : null;
      setCodingQuestion(l.question_description);
    } else {
      codingQuestionId = codingRoundQuestion.question_id;
    }
    if (localStorage.getItem("interviewId")) {
      setInterviewId(localStorage.getItem("interviewId"));
      setInterviewConfigured(true);
      setCodingRoundStarted(true);
    } else {

      fetch(baseURLArkajit + '/agent_feedback/initialise_interview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {
            "candidate_id": currentUser.uid,
            "created_by": "admin",
            "resume_round": candidateResumeCheckBox,
            "question_id": codingQuestionId?codingQuestionId:"1",
          }),
      })
        .then(response => response.json())
        .then(response => {
          if (response.status == "Success" && response.result?.interview_id) {
            setInterviewId(response.result.interview_id);
            localStorage.setItem("interviewId", response.result.interview_id);
            setInterviewConfigured(true);
            setCodingRoundStarted(true);
          }
        })
        .catch(error => console.error(error));
    }
    initializeRecording();
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const initializeRecording = () => {
    navigator.mediaDevices.getDisplayMedia({
      systemAudio: "exclude",
      video: true,
      audio: false
    }).then(async function (stream) {
      let newScreenRecorderObj = RecordRTC(stream, {
        type: 'video',
        mimeType: 'video/webm;codecs=vp9',
        frameRate: 10,
        bitsPerSecond: 32000,
      });
      newScreenRecorderObj.startRecording();
      if (newScreenRecorderObj.state == "recording") {
        setScreenRecordPermission("given");
      }
      setNativeStreams([stream]);
      setScreenRecorder(newScreenRecorderObj);
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      }).then(async function (stream) {
        let newRecorderObj = RecordRTC(stream, {
          type: 'video',
          mimeType: 'video/webm;codecs=vp9',
          frameRate: 10,
          bitsPerSecond: 32000
        });
        newRecorderObj.startRecording();
        if (newRecorderObj.state == "recording") {
          setVideoAudioPermission("given");
        }
        setVideoRecorder(newRecorderObj);
        setNativeStreams((prev) => [...prev, stream]);
        nativeStreams.forEach(stream =>{
          stream.getTracks().forEach(track => track.onended = function(){
            setScreenRecordPermission("denied");
            setVideoAudioPermission("denied");
          });
        })
      });
    }).catch(function (error) {
      console.log("error", error);
      setScreenRecordPermission("denied");
      setVideoAudioPermission("denied");
    })
  }

  useEffect(() => {
    if (!codingRoundStarted && screenRecordPermission=="given" && videoAudioPermission=="given" && screenRecorder?.state == "recording") {
      screenRecorder.stopRecording(function () {
        videoRecorder.stopRecording(function () {
          let screenBlob = screenRecorder.getBlob();
          let videoBlob = videoRecorder.getBlob();
          
          DiskStorage.Store({screenBlob,videoBlob})
          localStorage.setItem("screenBlobURL", URL.createObjectURL(screenBlob));
          localStorage.setItem("videoBlobURL", URL.createObjectURL(videoBlob));
          console.log("screen_recording", screenBlob,);
          console.log("camera_recording", videoBlob);

          const formData = new FormData();
          console.log(screenBlob.size, videoBlob.size, bytesToSize(screenBlob.size), bytesToSize(videoBlob.size));
          formData.append('screen_recording', screenBlob);
          formData.append('camera_recording', videoBlob);
          axios
            .post(
              baseURLArkajit + '/candidate_flow/get_recording',
              formData, {
              params: {
                interview_id: interviewId,
              }
            }
            )
            .then(function (response) {
              console.log("RECORDING_API_ERROR", response);
            })
            .catch(function (error) {
              console.log(error);
            });
          videoRecorder.reset();
          screenRecorder.reset();
          navigate('/coding-round/feedback');
          nativeStreams.forEach(stream => stream.getTracks().forEach(track => track.stop()));
        });
      });
    }
  }, [codingRoundStarted]);



  // function startCapture(displayMediaOptions) {
  //   setRecorder(navigator.mediaDevices
  //     .getDisplayMedia(displayMediaOptions)
  //     .catch((err) => {
  //       console.error(err);
  //       return null;
  //     }));
  // }

  // console.log("test case results", testCaseResults)
  const language = config.supportedLanguages.find(({ id }) => {
    return id === (selectedLanguageId)
  }).name;

  function handleEditorWillMount(monaco) {
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.Latest,
      module: monaco.languages.typescript.ModuleKind.ES2015,
      allowNonTsExtensions: true,
      lib: ['es2018'],
    });
  }

  useEffect(() => {
    setCode(examples[selectedLanguageId] || '');
  }, [selectedLanguageId, examples]);

  // useEffect(() => {
  //   // handleEditorChange(code);
  // }, []);

  const [code, setCode] = useState('');

  function handleEditorChange(value, event) {
    setCode(value);

  }


  function canShowLanguageSelector(languageId) {
    if (languageId === 7 || languageId === 18 || languageId === 19 || languageId === 37 || languageId === 13) {
      return true
    }
    return false;
  }


  // if (!interviewStarted) {
  //   return <InterviewStartLoading setInterviewStarted={setInterviewStarted} />
  // } else 
  if (!interviewConfigured || screenRecordPermission!="given" || videoAudioPermission!="given") {
    if(screenRecordPermission=="denied" || videoAudioPermission=="denied"){
      return <LoadingScreen loadingText={[
        "Reload the page and give permission to record screen and audio",
      ]} />;
    }else{
      return <LoadingScreen loadingText={[
        "Please wait, your Interview is getting Initialized",
      ]} />;
    }
  } else {


    return <div className={classes.root}>
      <div className={classes.left}>
        <div>
          <div className={classes.problemStatementHeading}>
            <div>
              <Typography className={classes.problemTitle}>Problem Statement: </Typography>
              <div className={classes.problemStatement}>
                <Typography variant="h7">{(codingRoundQuestion? codingRoundQuestion.question_description: codingQuestion)}</Typography>
              </div>
            </div>
            <div className={classes.options}>
              <div className={classes.languages}>
                <TextField
                  select
                  value={selectedLanguageId}
                  onChange={handleLanguageChange}
                  className="full-width"
                  variant="standard"
                >
                  {config.supportedLanguages.map(language => canShowLanguageSelector(language.id) && (
                    <MenuItem style={{backgroundColor:"white"}} key={language.id} value={language.id}>
                      {language.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

            </div>
          </div>

        </div>
        {
          !isMobile && (
            <MonacoEditor
              theme={monacoTheme}
              height="80vh"
              width={"100%"}
              path={language}
              defaultValue={examples[selectedLanguageId] || ''}
              defaultLanguage={language}
              options={options}
              beforeMount={handleEditorWillMount}
              onChange={handleEditorChange}
              onMount={(editor, monaco) => {
                setCode(examples[selectedLanguageId] || '');
              }}
            />
          )
        }
        <CodeRunner code={code} />
      </div>
      <div className={classes.right}>
        <ChatSection chat_interview_state={"coding_guidance"} chat={chat} setChat={setChat} code={code} />
      </div>
    </div>;
  }

}

export default Editor;
