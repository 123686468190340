import { useNavigate } from "react-router-dom";

export const useInterviewStateNavigation = (interview_state) => {
    const Navigate = useNavigate();

    const navigateToRoute = (interview_state) => {
        let path = "";
        switch (interview_state) {
            case "coding_guidance":
                path = "/coding-round/editor";
                break;
            case "completed":
                path = "/coding-round/feedback";
                break;
            case "resume_round":
                path = "/resume-round";
                break;
            default:
                break;
        }
        setTimeout(() => { Navigate(path) }, 3000);
        
    };
    return navigateToRoute;

}