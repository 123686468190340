import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  summary: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#858585"

  },
  runButton: {
    backgroundColor: "#D9D9D9",
    display: "flex",
    border: "none",
    borderRadius: "5px",
    height:"35px",
    width: "13%",
    gap: "8px",
    fontWeight: "bold",
    color: "white",
    cursor: "",
    alignItems: "center",
    justifyContent: "center",
  },
  runButtonBlue: {
    display: "flex",
    border: "none",
    height:"35px",
    width: "13%",
    borderRadius: "5px",
    gap: "8px",
    fontWeight: "bold",
    color: "white",
    cursor: "",
    alignItems: "center",
    backgroundColor: "#0F70EB",
    justifyContent: "center",
  },
  accordationSummary: {
    padding: 0,
  },
  output: {
    whiteSpace: "pre-wrap",
    rotate: "180deg",
    backgroundColor: "#F9F9F9",
    height: "15vh",
    padding: "1%",
    width: "100%",
    overflowY:'auto'
  },
  ranIn: {
    fontSize: "1rem",
    display: "flex",
  },
  ranInMS: {
    color: "black"
  },
  linearLoader: {
    height: "5px",
    width: "100%",
  },
  linearLoaderRoot: {
  },
  outputConsole: {
    rotate: "180deg",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }
}));

export default useStyles;