import React, { useEffect, useRef, useState } from 'react';

import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import SendIcon from '@material-ui/icons/Send';


// import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

import { useSpeechSynthesis, useSpeechRecognition } from 'react-speech-kit';


import { useStore } from 'store';


import useStyles from './useStyles';
import { Paper, Typography } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import Conversation from '../Conversation';
import { useInterviewStateNavigation } from 'hooks/navigation/interview_state/InterviewStateNavigation';
import { useAuth } from 'firebase/AuthContext';

const ChatSection = ({ chat_interview_state, code = "", width }) => {


  const customNavigation = useInterviewStateNavigation();

  const [chat, setChat] = useState([
    {
      "text": "Hi there! I'm your Interviewer, welcome to your coding round... please go through the problem statement on the left and let me know once you have read it!",
      "isUser": false
    }
  ]);

  const classes = useStyles();
  const {
    state: { baseURLArkajit, interviewId },
    actions: { setInterviewState },
  } = useStore();

  const [isRecording, setIsRecording] = useState(false);
  const [transcriptProcessing, setTranscriptProcessing] = useState(false);
  const [micStopped, setMicStopped] = useState(false);

  const [transcript, setTranscript] = useState('');
  const chatContainerRef = useRef(null);
  const handleInputChange = (e) => {
    const input = inputRef.current;
    input.style.height = 'auto';
    input.style.height = `${Math.min(input.scrollHeight, 200)}px`;
  };

  const inputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [textToSpeech, setTextToSpeech] = useState('');

  const subscriptionKey = 'fcff6df81c8b4a40a521d13396cc8204';
  const region = 'eastasia';
  // const languageCode = 'en-US';
  const languageCode = 'en-AU';


  const speak = (args = {}) => {
    const { voice = null, text = '', rate = 1, pitch = 1, volume = 1 } = args;
    // Firefox won't repeat an utterance that has been
    // spoken, so we need to create a new instance each time
    const utterance = new window.SpeechSynthesisUtterance();
    utterance.text = text;
    utterance.voice = voice;
    utterance.rate = rate;
    utterance.pitch = pitch;
    utterance.volume = volume;
    window.speechSynthesis.speak(utterance);
  };

  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setTranscript(prevTranscript => prevTranscript + result + ' ');
    },
  });


  useEffect(() => {
    // speak({ text: "Hi there! I'm your Interviewer, welcome to your coding round... please go through the question on the left and let me know once you have!" });
    speak({ text: chat[0].text });

    //for audio permissions
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        // Set the audio stream as the input for the SpeechRecognizer
        // audioConfig.updateFromMicrophoneInput(stream);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if(chatContainerRef?.current){
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight ;
      chatContainer.lastChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [chat]);

  const startRecognition = () => {
    setIsRecording(true);
    listen({ interimResults: false, lang: languageCode });
  };


  function sendUserInputToAgent() {
    if (transcriptProcessing || transcript.length == 0) return;
    setTranscriptProcessing(true);

    //mocking API response
    if (transcript.length > 0) {
      let currentChat = chat;
      let currentTranscript = transcript;
      currentChat = [...currentChat, { text: currentTranscript, isUser: true }];
      setChat(currentChat)
      setTranscript('')

        // setTimeout(() => {
         
        //   let currentChat2 = currentChat;
        //   speak({ text: "HI this is a sample response from the interviewer agent" });
        //   currentChat2 = [...currentChat2, { text: "HI this is a sample response from the interviewer agent", isUser: false }];
        //   setChat(currentChat2)
        //   const chatContainer = chatContainerRef.current;
        //   chatContainer.scrollTop = chatContainer.scrollHeight;
        //   chatContainer.lastChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        //   setTranscriptProcessing(false);
        // }, 2000);
          
        

      fetch(baseURLArkajit + '/agent_feedback/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            candidate_response: currentTranscript,
            interview_id: interviewId,
            code: code
          }
        ),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Response received:', data);
          // let utterance = new SpeechSynthesisUtterance();
          if (data.result?.interviewer_response) {
            let currentChat2 = currentChat;

            let response = data.result?.interviewer_response;

            // Create a new SpeechSynthesisUtterance object
            // Set the text and voice of the utterance
            // utterance.text = response;
            // Speak the utterance
            speak({text:response});
            currentChat2 = [...currentChat2, { text: response, isUser: false }];
            setChat(currentChat2)
          } else {
            // Speak the utterance
            speak({text:"API Failed"});
            setChat(prevChat => [...prevChat, { text: "API Failed", isUser: false }]);
          }

          if (data.result?.interview_state != chat_interview_state) {
            customNavigation(data.result?.interview_state);
          }
          setTranscriptProcessing(false);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }


  const stopRecognition = () => {
    setIsRecording(false);
    stop();
  };



  const handleMouseDown = () => {
    if (!isPopupOpen) {
      setIsPopupOpen(true);
    } else {
      startRecognition();
    }
  };


  const handleMouseUp = () => {
    if (isPopupOpen) {
      stopRecognition();
    }
  };


  const handleMicClick = () => {
    if (isRecording) {
      stopRecognition();
    } else {
      startRecognition();
    }
  }



  return (
    <div className={classes.root} onKeyPress={(event) => {
      if ((event.key === 'Enter' && event.ctrlKey) || (event.key === 'Enter' && event.metaKey)) {
        sendUserInputToAgent();
      }
    }}>
      <Conversation chat={chat} chatContainerRef={chatContainerRef} interviewerThinking={transcriptProcessing}/>

      <div className={classes.inputSection} style={{ width: width ? `${width - 2}%` : `53%` }}>
        {/* <div className={classes.linearLoader}>
          {transcriptProcessing && <LinearProgress classes={{ root: classes.linearLoaderRoot }} />}
        </div> */}
        <Paper className={`${classes.transcript} ${isRecording ? 'recording' : ''}`}>
          <textarea
            ref={inputRef}
            className={classes.inputField}
            value={transcript}
            onChange={(e) => {
              setTranscript(e.target.value);
              handleInputChange(e);
            }}
            placeholder='Type here or use the microphone to converse..'
          />
          <div className={classes.buttons}>
            {isRecording ? <StopIcon
              style={{ cursor: "pointer", color: "#ee3d5a", alignSelf: "flex-end" }}
              className={`${isRecording ? classes.wave : ''}`}
              onClick={() => handleMicClick()} />
              : <MicIcon
                onClick={() => handleMicClick()}
                style={{ cursor: "pointer", color: "#ee3d5a" }} />
            }
            <SendIcon style={{ cursor: "pointer", color: '#0F70EB' }} onClick={() => sendUserInputToAgent()} />
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default ChatSection;
