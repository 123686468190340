import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import useStyles from './useStyles';
import { styled } from '@material-ui/core';

const LoadingData = ({ color = "#0F70EB", children, loaded = false, style={} }) => {
  const classes = useStyles();
  const CustomLoader = styled(CircularProgress)(({ theme }) => ({
    width: 300,
    color:color
  }));
  if (loaded) {
    return children;
  }
  return <div className={classes.root} style={style}>
    <CustomLoader
      thickness={4}
    />
  </div>
};


export default LoadingData;
