import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f3f4f6',
    paddingInline: '2%',
    paddingTop: '3%',
  },

}));

export default useStyles;
