import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    width:"30%"
  },
  languages: {
    width: '100%',
    padding:10,
    // marginBottom: 20,
    // marginTop: 20,
  },
  title: {
    marginBottom: 10,
    marginTop: 10,
  },
  editor: {
    marginBottom: 12,
    marginTop: 12,
  },
  runButton: {
    backgroundColor: '#21b548',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  runOutput: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridAutoRows: 'minmax(min-content, max-content)',
    gridGap: '10px',
    backgroundColor: theme.palette.grey.A400,
    color: '#fff',
    padding: 10,
    marginBottom: 20,
    marginTop: 20,
    overflow: 'auto',
    minHeight: 300,
  },
  problemStatementHeading: {
    display:"flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 10,
  },
  problemStatement: {
    backgroundColor: theme.palette.grey.A400,
    color: '#fff',
    padding: 10,
    paddingTop:20,
    paddingBottom:20,
    marginTop: 20,
    overflow: 'auto',
  },
  formatRoot: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey.A400,
    borderRadius: theme.spacing(1),
  },
  formatHeading: {
    // backgroundColor: theme.palette.grey.A400,
    color: '#fff',
  },
  formatSection: {
    marginBottom: theme.spacing(2),
    color: '#fff',

  },
  formatLabel: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    color: '#fff',
  },
  formatExample: {
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
    color: '#fff',

  },
  
}));

export default useStyles;
