import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '0%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
    'padding-top': 15,
    'margin-bottom': 15,
  },

  left: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    borderWidth:"0px 1px 0px 0px",
    borderStyle:"solid",
    borderRadius:"0px",
    borderColor:"#DCDCDB"
  },
  right: {
    width: '55%',
  },
  problemStatementHeading: {
    display:"flex",
    width:"100%",
    justifyContent: 'space-between',
    marginBottom: "20px",
    paddingLeft: "10px",
  },
  problemStatement: {
    width:"80%",
    paddingBlock: "10px",
  },
  options: {
    width:"20%",
    marginInline: "20px",
  },
  languages: {
    
  },
  problemTitle:{
    fontFamily: "'Manrope', sans-serif",
    fontWeight: 600,
    fontSize: "1.1rem",
  },
  accordion: {
    position: 'relative',
    overflow: 'hidden',
  },
  accordionHeader: {
    position: 'relative',
    zIndex: 1,
  },
  accordionButton: {
    display: 'block',
    width: '100%',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
  },
  accordionContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '10px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-100%)',
    transition: 'transform 0.3s ease-in-out',
  },
  open: {
    '& $accordionContent': {
      transform: 'translateY(0)',
    },
  },
}));

export default useStyles;
