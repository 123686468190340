/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const InputField = ({ size, className, value, onChange, placeholder, text = "Enter your email address", textarea=false }) => {

  if (textarea) {
    return (
      <textarea className={`input-field ${size} ${className}`} type="text" value={value} onChange={onChange} placeholder={placeholder} /> 
    );
  }
  return (
    // <div className={`input-field ${size} ${className}`}>
      <input className={`input-field ${size} ${className}`} type="text" value={value} onChange={onChange} placeholder={placeholder} /> 
    // </div>
  );
};

InputField.propTypes = {
  size: PropTypes.oneOf(["primary", "large"]),
  text: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
