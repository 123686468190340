/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Button = ({ disabled=false, type, size, className, text = "Schedule a Demo", href, handleClick}) => {
  const handleLinkClick = (event, id) => {
    event.preventDefault();
    if(id){
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    }else{
      handleClick();
    }

  };
  return (
    <div style={{
        cursor:disabled?"not-allowed":"pointer",
        backgroundColor:disabled?"#969696":"#0e70eb"
      }} className={`button size-0-${size} type-${type} ${className}`} onClick={(event) => handleLinkClick(event, href)}>
      <div className="notify-me">
        {(size === "large" || (size === "small" && type === "secondary")) && <>Notify Me</>}

        {(size === "normal" || (size === "small" && type === "primary")) && 
          <>{text}</>}
      </div>
    </div>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["large", "normal", "small"]),
  text: PropTypes.string,
};
