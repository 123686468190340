import { useAuth } from 'firebase/AuthContext'
import React from 'react'
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({children}) {
  
    const {currentUser} = useAuth();

    const sampleAuth = 
    {
        "uid": "39pFIeUNRZR9ZMRnVN5OfANmz4o1",
        "email": "shamith2709@gmail.com",
        "emailVerified": true,
        "displayName": "shamith rao",
        "isAnonymous": false,
        "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocLBMFL1rG_GuIUblgBEiyTX7fqty9O75ddSSB6vcBFhAlN9=s96-c",
        "providerData": [
            {
                "providerId": "google.com",
                "uid": "100284328006654926850",
                "displayName": "shamith rao",
                "email": "shamith2709@gmail.com",
                "phoneNumber": null,
                "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocLBMFL1rG_GuIUblgBEiyTX7fqty9O75ddSSB6vcBFhAlN9=s96-c"
            },
            {
                "providerId": "password",
                "uid": "shamith2709@gmail.com",
                "displayName": "shamith rao",
                "email": "shamith2709@gmail.com",
                "phoneNumber": null,
                "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocLBMFL1rG_GuIUblgBEiyTX7fqty9O75ddSSB6vcBFhAlN9=s96-c"
            }
        ],
        "stsTokenManager": {
            "refreshToken": "AMf-vBxTFi6F5F1Pe3I4305LJ04xVmRIqyfdDxKBFTxC6kHyDy4X1OUwWKVq9gArvAWqBpBQO4OSoZIPFYiIX_IxxG_FpCwfINuUfs2kxeCZx-hlTnZQYQ5Z-L1-zFePNoP1WLOhJMjEXHd4nF-enGiL7b5LOIhd3QHQcm_mkeU3bDocE2y2fuCkPBQFwOiIwHyVNK7iUW3c8n84OQx0BMrd2-6ffPgCJJM30mMA2stsxltW3VsvgrMkAlwQ_4LmF5huo8HqwZDiDFpc-W6zkm69Gv5iNihR05QGBe37AkO5cr4UazEes-N5Zt0xbSXYbw5utnXMK93xIrI9nE8CDkuP1fi_td1_DGneR0Fxf6Mw_Conj2OV1_dTtQb1VnMm0UjZ1PyJM6u0lpW5rPLgDAcSXZpf2VpjAbCiQNSm3NM_zaNvPrlbxPmk5omjyN3PANqUWss46FYx",
            "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjBiYmQyOTllODU2MmU3MmYyZThkN2YwMTliYTdiZjAxMWFlZjU1Y2EiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoic2hhbWl0aCByYW8iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jTEJNRkwxckdfR3VJVWJsZ0JFaXlUWDdmcXR5OU83NWRkU1NCNnZjQkZoQWxOOT1zOTYtYyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9pbnRlcnZpZXctYWktYXV0aCIsImF1ZCI6ImludGVydmlldy1haS1hdXRoIiwiYXV0aF90aW1lIjoxNzAxNTcyNTExLCJ1c2VyX2lkIjoiMzlwRkllVU5SWlI5Wk1SblZONU9mQU5tejRvMSIsInN1YiI6IjM5cEZJZVVOUlpSOVpNUm5WTjVPZkFObXo0bzEiLCJpYXQiOjE3MDE1NzI1MTEsImV4cCI6MTcwMTU3NjExMSwiZW1haWwiOiJzaGFtaXRoMjcwOUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwMDI4NDMyODAwNjY1NDkyNjg1MCJdLCJlbWFpbCI6WyJzaGFtaXRoMjcwOUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.FQzBNvYM_6ZSfXZ2DipZKbBzYFYNO1izfJLf0apgvIPHqAN1Cx13M_itIOc89_DxgWokSvd4GwmgQWJK3yvecYwb2tM5CSQLsiTfss0Aw16HwODBCTUf0Kegzn8gXe3vhV0DFHUMsSfIscsiwaTJVSbXnWDokTlDKejmuDgX1OCb6krlFUWcxdYqF-Gox8KBTdB02cmYMMaYoPsuRoKoyWvzIGchndXgwSMPsBS4s3TiBYlJY_wbYTg0EDJELLUVZSDt9PVM2kNwoRExz7TLGEFw9I0u1HuffZ1Af9Kt1AH9WTiXXjA_NLo2RlwftLVpqfLroe33LW5gzBdoTH1iaQ",
            "expirationTime": 1701576111520
        },
        "createdAt": "1701361681582",
        "lastLoginAt": "1701572511273",
        "apiKey": "AIzaSyDhO1Rp5vSWdEQQo-i3pBI7hdmu70lXMNA",
        "appName": "[DEFAULT]"
    };
    
    if(!currentUser)
    {
        return <Navigate to='/login'/>
    }
    return children;
}
