import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


import { useStore } from 'store';

import useStyles from './useStyles';
import { InputAdornment, TextField } from '@mui/material';
import LoadingData from 'components/LoadingData';
import SearchIcon from '@mui/icons-material/Search';



const CandidateList = ({ org_id }) => {

  // const { signOut, currentUser } = useAuth();
  const { state: { baseURLArkajit } } = useStore();
  const navigate = useNavigate();

  const classes = useStyles();
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    let orgId = "thc";
    if (localStorage.getItem('org_id')) orgId = localStorage.getItem('org_id');

    fetch(baseURLArkajit + `/hr_flow/candidate_list?organisation_id=${orgId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.result.candidates) {
          setCandidates(response.result.candidates);
        }
      })
      .catch(error => console.error(error));
  }, []);

  const navigateToCandidateDashboard = (user_id) => {
    // window.open(`/candidate/dashboard`, "_blank");
    window.open(`/candidate/dashboard?user_id=${user_id}`, '_blank');
    // navigate('/candidate/dashboard', { state: { user_id: user_id } });
    
    //logic to send user id to can dash
  }

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredCandidates = candidates.filter(candidate =>
    candidate.username.toLowerCase().includes(searchText.toLowerCase())
    || candidate.organisation_id.toLowerCase().includes(searchText.toLowerCase())
  );


  return (
    <div className={classes.root}>
      <div className={classes.headerText}>Candidates</div>
      <div className={classes.headerDiv}>
        <TextField
          value={searchText}
          onChange={handleSearchChange}
          size='small'
          placeholder='Search by Name/Org'
          InputProps={{
            style: {
              backgroundColor: 'white',
              height: '35px',
              fontFamily: "'Kumbh Sans', sans-serif",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{ color: 'blue' }}
        />
      </div>
      <div style={{ overflow: 'auto', height: '70vh'}}>
        <LoadingData style={{ padding: "5%" }} loaded={candidates.length > 0}>
          <div className={classes.headerRow}>
            <div className={classes.headerColumn} style={{ width: "40%" }}>Candidate Name</div>
            <div className={classes.headerColumn}>Number Of Interviews</div>
            <div className={classes.headerColumn}>Best Score</div>
            <div className={classes.headerColumn}>Average Score</div>
          </div>
          {filteredCandidates.map((int, ind) => (
            <div className={classes.interviewDiv} style={{ backgroundColor: ind % 2 != 0 ? "white" : "#f8f8f8" }}>
              <div className={classes.interviewTitle} style={{ width: "40%" }}>
                {int.username}
              </div>
              <div className={classes.interviewTitle} style={{ width: "20%" }}>
                {int.no_of_interviews}
              </div>
              <div className={classes.interviewTitle} style={{ width: "20%" }}>
                {int.best_score}
              </div>
              <div className={classes.interviewScore} style={{ width: "20%" }}>
                <div className={classes.score} onClick={() => navigateToCandidateDashboard(int.user_id)}>
                  {int.score ? int.score + "/100" : ""}
                </div>
              </div>
            </div>
          ))}
        </LoadingData>
      </div>
    </div>
  );
}

export default CandidateList;
