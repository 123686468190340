import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    fullpage: {
        height: `96vh`,
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(243, 244, 246, 1)'
    },
    heading:{
        letterSpacing: '0.6px',
        wordSpacing: '2px',
        marginTop: '0',
        fontSize: '30px',
        marginBottom: '10px',
    },
    starttext:{
        fontFamily: "'Kumbh Sans', sans-serif",
        letterSpacing:'1px',
        color:'#0F70EB',
        fontSize:'22px',
        margin:0,
    },
    blob1:{
        pointerEvents:'none',
        opacity:0.7,
        position:'absolute',
        height:'30px',
        width:'30px',
        top:'45vh',
        left:'16vw',

    },
    blob2:{
        pointerEvents:'none',
        opacity:0.5,
        position:'absolute',
        top:'0',
        right:'20vw',
        
    },
    blob3:{
        pointerEvents:'none',
        position:'absolute',
        opacity:0.8,
        height:'30px',
        width:'30px',
        top:'12vh',
        left:'0vw',
    },
    form: {
        zIndex:'1',
        padding:'4%',
        paddingInline:'8%',
        rowGap:'6px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.6);',
        borderRadius:'10px',
    },
    bottom: {
        fontSize:'small',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: "'Kumbh Sans', sans-serif"
    },
    input:{
        '&:focus':{
            border:'none',
            outline: "1px solid black",
        },
        '&::placeholder':{ 
            color:"rgba(192, 192, 192, 1)",
            fontStyle:'normal',
        },
        marginTop:'5px',
        marginBottom:'5px',
        padding: '8px',
        paddingInline: '14px',
        width:'100%',
        borderRadius: '3px',
        borderColor:'rgba(208, 208, 208, 0.5)',
        borderWidth: '1px',
        fontFamily: "'Kumbh Sans', sans-serif"
    },
    inputbottom:{
        '&:focus':{
            border:'none',
            outline: "1px solid black",
        },
        '&::placeholder':{ 
            color:"rgba(192, 192, 192, 1)",
            fontStyle:'normal',
        },
        padding: '8px',
        paddingInline: '14px',
        marginTop:'5px',
        marginBottom:'14px',
        width:'100%',
        borderRadius: '3px',
        borderColor:'rgba(208, 208, 208, 0.5)',
        borderWidth: '1px',
        fontFamily: "'Kumbh Sans', sans-serif"
    },
    line:{
        width:'90%',
        height:'12.5px',
        borderBottom: "1px solid #a6a6a6",
        textAlign: 'center',
    },
    span:{
        fontFamily: "'Kumbh Sans', sans-serif",
        fontSize:"15px",
        color:"#a6a6a6",
        backgroundColor: "#f2f2f2",
        // backgroundColor: 'rgba(255, 255, 255, 0.6);',
        padding:"0 10px",
    },
    subheading:{
        color:'#a6a6a6',
        marginBottom:'5px',
        fontFamily: "'Kumbh Sans', sans-serif"
    },
    icon:{
        marginTop:'10px',
    },
    submit:{
        width:'100%',
        marginBottom:'10px',
        padding: '8px',
        borderWidth: '1px',
        borderRadius: '3px',
        borderColor:'transparent',
        backgroundColor:'#0F70EB',
        color:"white",
        fontFamily: "'Kumbh Sans', sans-serif"
    },
}));

export default useStyles;
