import React, { useEffect, useState } from 'react';
import useStyles from './useStyles';
import DashboardAccount from 'components/DashboardAccount';
import { useAuth } from 'firebase/AuthContext';
import { useStore } from 'store';
import HRAccountSection from './HRAccountSection';
import CandidateList from './CandidateList/CandidateList';

const HRDashboard = _ => {

  const classes = useStyles();
  // const { state: { editorMode, interviewState }, actions: { setInterviewState } } = useStore();
  const { signOut, currentUser } = useAuth();
  const [selectedView, setSelectedView] = useState("candidate");
  const [orgId, setOrgId] = useState(null);
  const { state: { baseURLArkajit }, actions: { setInterviewState } } = useStore();
  

  useEffect(() => {
    if(localStorage.getItem('org_id')){
      setOrgId(localStorage.getItem('org_id'))
    }else{
      setOrgId("thc")
    }
  }, []);

  return (
    <div className={classes.root}>
      <HRAccountSection selectedView={selectedView} setSelectedView={setSelectedView} orgId={orgId}/>
      <div className={classes.rightDiv}>
        <CandidateList org_id={orgId}/>
      </div>
    </div>
  );
}

export default HRDashboard;
