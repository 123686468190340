import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useStyles from './useStyles';
import { useAuth } from 'firebase/AuthContext';
import { useStore } from 'store';

export default function Login() {

    const { signIn, signInWithGoogle } = useAuth();
    const [validation, setValidation] = useState(null)
    const email = useRef();
    const password = useRef();
    const classes = useStyles();
    const Navigate = useNavigate();

    const {
        state: { baseURLArkajit },
    } = useStore();

    const submitAction = async (e) => {
        setValidation(null)

        e.preventDefault();
        if (email.current.value === '' || email.current.password === '') {
            setValidation('Please enter a valid email / password')
        }
        else {
            signIn(email.current.value, password.current.value)
                .then((data) => {
                    fetch(baseURLArkajit + '/candidate_flow/login', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(
                            {
                                user_id: data.user?.uid,
                                user_name: data.user?.displayName ? data.user?.displayName : data.user?.email,
                                email: data.user?.email,
                            }),
                    })
                        .then((response) => response.json())

                        .then((data) => {
                            console.log("Login Data", data)
                            localStorage.setItem('user_role', data.result?.role)
                            localStorage.setItem('org_id', data.result?.organisation_id)
                            if (data.result?.role == 'hr') Navigate('/hr/dashboard');
                            else Navigate('/candidate/dashboard')
                        })
                })
                .catch(err => {
                    setValidation(err.message);
                })

        }
    }

    const googleSignIn = async () => {

        signInWithGoogle()
            .then((data) => {
                // console.log("Google Sign In Successful", data)
                fetch(baseURLArkajit + '/candidate_flow/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            user_id: data.user?.uid,
                            user_name: data.user?.displayName,
                            email: data.user?.email,
                            photo_url: data.user?.photoURL,
                        }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log("Login Data", data)
                        localStorage.setItem('user_role', data.result?.role)
                        localStorage.setItem('org_id', data.result?.organisation_id)
                        if (data.result?.role == 'hr') Navigate('/hr/dashboard');
                        else Navigate('/candidate/dashboard')
                    })
                    .catch(error => console.error(error));
            })
            // .then((data) => {
            //     console.log(data)
            //     Navigate('/candidate/dashboard')
            // })
            .catch((err) => {
                setValidation(err.message)
            })

    }
    return (
        <div className={classes.fullpage}>
            {validation && <p
                style={{
                    textAlign: 'center',
                }}
            >{validation}</p>}
            <div className={classes.blob1}>
                <img alt='Blob1' width='500px' height='500px' src='./Rectangle4.png' />
            </div>
            <div className={classes.blob2}>
                <img alt='Blob2' width='400px' height='400px' src='./Ellipse2.png' />
            </div>
            <div className={classes.blob3}>
                <img alt='Blob3' width='300px' height='400px' src='./Ellipse4.png' />
            </div>
            <form className={classes.form}>
                <p className={classes.starttext} >Welcome back!</p>
                <h1 className={classes.heading}>Let's Pick up Where you left off</h1>
                <input type='email' ref={email} className={classes.input} placeholder='Email' />
                <input type='password' ref={password} className={classes.input} placeholder='Password' />
                <div className={classes.forgotPassword}>
                    <Link
                        style={{
                            textDecoration: 'none',
                        }}
                        to='/forgot' className={classes.forgotPassword} >
                        Forgot Password ?
                    </Link>
                </div>
                <button onClick={submitAction} className={classes.submit}>Sign In</button>
                <div className={classes.line}>
                    <span className={classes.span}>
                        OR
                    </span>
                </div>
                <p className={classes.subheading}>Sign In With</p>
                <img
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={googleSignIn} alt='Google Icon' width={"50"} height={"50"} src='/icons/GoogleIcon.png' />
                <div className={classes.bottom}>
                    <p>Not a member?&nbsp;</p>
                    <Link
                        style={{
                            color: '#0F70EB',
                        }}
                        to='/signup'>
                        Sign up
                    </Link>
                </div>
                {/* {currentUser && currentUser.email}
                <button
                onClick={()=>{signOut()}}
                >Sign Out</button> */}
            </form>
        </div>
    )
}
