import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    paddingBottom: "2%",
    justifyContent: "space-between",
  },
  statDiv: {
    backgroundColor: "#1170eb",
    borderRadius: "8px",
    paddingInline: "2%",
    paddingBlock : "1%",
    display: "flex",
    width:'30%',
    justifyContent: "space-between",
  },
  statTitle: {
    color: "white",
    fontSize: "1.2rem",
    fontWeight: "500",
    paddingInline: "2%",
    paddingBlock: "3%",
  },
  statValue: {
    color: "white",
    fontSize: "3rem",
    paddingInline: "2%",
    fontWeight: "500",
  },
}));

export default useStyles;
