import React from 'react';

import ResumeLoader from 'sections/Resume';
import { asyncComponentLoader } from 'utils';

const ResumeSection = asyncComponentLoader(ResumeLoader);

const Content = _ => <div>
  <ResumeSection/>
</div>;

export default Content;
