import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  transcript: {
    width: '85%',
    minHeight: '50px',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[800],
    color: '#fff',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    cursor: 'text',
    boxShadow: '0 0 4px #fff',
    transition: 'box-shadow 0.3s ease-in-out',
    '&.editable': {
      boxShadow: '0 0 4px rgba(76, 175, 80, 0.4)',
      cursor: 'auto',
    },
    '&.recording': {
      boxShadow: '0 0 8px rgba(76, 175, 80, 0.8)',
      animation: '$pulse 1s infinite',
    },
    marginLeft:"1%"
  },
  button: {
    position: 'fixed',
    bottom: '0',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '64px',
    height: '64px',
    borderRadius: '50%',
    backgroundColor: '#4caf50',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },

  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-start',
    // width: '50%',
    height:"80%",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[800],
    alignItems:"center",
    // justifyContent:"flex-end",
    borderRadius: '4px',
    overflowY: 'auto',
  },
  userMessage: {
    backgroundColor: '#4caf50',
    color: '#fff',
    padding: theme.spacing(1),
    alignSelf: 'flex-end',
    borderRadius: '4px',
    maxWidth: '80%',
    wordWrap: 'break-word',
    marginBottom:"5%"
  },
  botMessage: {
    backgroundColor: '#283593',
    padding: theme.spacing(1),
    alignSelf: 'flex-start',
    borderRadius: '4px',
    maxWidth: '80%',
    wordWrap: 'break-word',
    marginBottom:"5%"
  },
  inputSection:{
    display:'flex',
    flexDirection:"row",
    width:"100%",
    alignItems:"center",
  },
  micButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2.5%',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#4caf50',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    marginLeft: '3%',
    position: 'relative',
    overflow: 'hidden',
  },
  wave: {
    animation: '$wave 1s ease-in-out infinite',
  },
  '@keyframes wave': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));