import React, { useState } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';

import { useStore } from 'store';

import classNames from 'classnames';
import useStyles from './useStyles';

const Candidate = _ => {

  const Navigate = useNavigate();
  const classes = useStyles();
  const { state: { editorMode, interviewState }, actions: { setInterviewState } } = useStore();

  return (
    <Paper elevation={0} square={true} className={classNames('full-size', classes.root)}>
      <Outlet />
    </Paper>
  );
}

export default Candidate;
