import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import codePic from "../../../../icons/vs-code.png"

import { useStore } from 'store';

import useStyles from './useStyles';
import { Chip, ThemeProvider } from '@mui/material';
import { chipTheme } from './config';
import { useAuth } from 'firebase/AuthContext';

import netflixLogo from "../../../../icons/netflix-logo.png"
import amazonLogo from "../../../../icons/amazon-logo.png"
import googleLogo from "../../../../icons/google-logo.png"
import metaLogo from "../../../../icons/meta-logo.png"
import LoadingData from 'components/LoadingData';
import { UserRole } from 'utils/enums/UserRole';

const InterviewList = ({ user_id }) => {

  const { state: { baseURLArkajit, candidateResumeCheckBox },
    actions: { setCandidateResumeCheckBox, setCodingRoundQuestion } } = useStore();


  const Navigate = useNavigate();
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [resumeChecks, setResumeChecks] = useState([]);
  const [userRole, setUserRole] = useState(UserRole.CANDIDATE);

  const getCompanyLogo = (company) => {
    if (company == "Netflix") {
      return netflixLogo;
    }
    else if (company == "Amazon") {
      return amazonLogo;
    }
    else if (company == "Aoogle") {
      return googleLogo;
    }
    else {
      return metaLogo;
    }
  }

  useEffect(() => {
    if (localStorage.getItem("user_role")) setUserRole(localStorage.getItem("user_role"));

    fetch(baseURLArkajit + `/candidate_flow/get_questions/?user_id=${user_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.result.questions) {
          setQuestions(response.result.questions);
        }
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    let tempResumeChecks = [];
    questions.forEach(() => tempResumeChecks.push(false));
    setResumeChecks(tempResumeChecks);
  }, [questions]);

  useEffect(() => {
    console.log(candidateResumeCheckBox);
  }, [candidateResumeCheckBox]);

  const updateResumeChecks = (index, val) => {
    let tempResumeChecks = [...resumeChecks];
    tempResumeChecks[index] = val;
    setResumeChecks(tempResumeChecks);
  }

  const startCodingRound = (index) => {
    if (userRole === UserRole.CANDIDATE) {
      setCandidateResumeCheckBox(resumeChecks[index]);
      setCodingRoundQuestion(questions[index]);
      localStorage.removeItem("interviewId");
      Navigate("/coding-round/editor");
    }
  }


  function getChipColor(difficulty) {
    if (difficulty == "easy") {
      return "primary";
    }
    else if (difficulty == "medium") {
      return "warning";
    }
    else {
      return "error";
    }
  }
  const navigateToFeedback = (interview_id) => {
    window.open(`/coding-round/feedback?interivew_id=${interview_id}`, "_blank");
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  function getQuestionActionText(interview) {
    if (interview.interview_id && interview.score) {
      return "Last Feedback";
    }
    else if (userRole === UserRole.CANDIDATE) {
      return "Start";
    } else {
      return "";
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerDiv}>
        <img className={classes.codeIcon} src={codePic}></img>
        <div className={classes.headerText}>Mock interviews</div>
      </div>
      <LoadingData style={{ padding: "5%" }} loaded={questions.length > 0}>
        {questions.map((int, ind) => (
          <div className={classes.interviewDiv}>
            <div className={classes.interviewTitle} style={{ width: "50%" }}>
              {int.question_name}
            </div>
            <div className={classes.interviewScore} style={{ width: "10%" }}>
              {/* <input
              type="checkbox"
              style={{ marginInline: "8%", cursor: "pointer", display:!int.interview_id?"flex":"none"}}
              onChange={(e) => updateResumeChecks(ind, e.target.checked)} />
            {!int.interview_id?"Resume":""} */}
            </div>
            <div className={classes.resumeCheckbox} style={{ width: "10%" }}>

              <div className={classes.score}>
                {int.score ? int.score + "/100" : ""}</div>
            </div>
            <div className={classes.interviewCompany} style={{ width: "10%" }}>
              <img
                height={"35px"}
                width={"35px"}
                src={getCompanyLogo(int.company_asked)}
                style={{
                  borderRadius: "50%",
                }}></img>
            </div>
            <div className={classes.interviewDifficulty} style={{ width: "10%" }}>
              <ThemeProvider theme={chipTheme}>
                <Chip label={capitalizeFirstLetter(int.difficulty)}
                  color={getChipColor(int.difficulty)} />
              </ThemeProvider>
            </div>
            <div className={classes.startDiv} style={{ width: "10%" }}>
              <div
                className={classes.startButton}
                onClick={() => int.interview_id ? navigateToFeedback(int.interview_id) : startCodingRound(ind)}
              >
                {getQuestionActionText(int)}
              </div>
            </div>
          </div>
        ))}
      </LoadingData>
      <div className={classes.footerDiv}></div>
    </div>
  );
}

export default InterviewList;
