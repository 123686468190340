import React, { useEffect, useState } from 'react';
import useStyles from './useStyles';
import InterviewList from './InterviewList/InterviewList';
import DashboardStats from './DashboardStats/DashboardStats';
import LastInterviewStat from './LastInterviewStat/LastInterviewStat';
import { useAuth } from 'firebase/AuthContext';
import { useStore } from 'store';
import DashboardAccount from './DashboardAccount';
import { useLocation } from 'react-router-dom';

const CandidateDasboard = _ => {

  const classes = useStyles();
  // const { state: { editorMode, interviewState }, actions: { setInterviewState } } = useStore();
  const { signOut, currentUser } = useAuth();
  const { state: { baseURLArkajit }, actions: { setInterviewState } } = useStore();
  const [photo_url, setPhotoUrl] = useState(null);
  const [user_name, setuserName] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get('user_id'); // Read values passed on state

  // const dashboardStats=[
  //   {
  //     "title": "Applied Interviews",
  //     "value": 10,
  //   },
  //   {
  //     "title": "Saved Interviews",
  //     "value": 10,
  //   },
  //   {
  //     "title": "Available Interviews",
  //     "value": 70,
  //   }
  // ]
  const lastInterviewStat = {
    "title": "Last Interview",
    "id": "288997023948",
    "problemTitle": "Three Sum",
    "status": "Completed",
  }
  const questions = [
    {
      "question_id": "1",
      "question_name": "3Sum",
      "company_asked": "amazon",
      "view_feedback": false,
      "difficulty": "easy",
      "interview_id": null,
      "score": null
    },
    {
      "question_id": "2",
      "question_name": "Reverse Integer",
      "company_asked": "Google",
      "difficulty": "medium",
      "view_feedback": false,
      "interview_id": null,
      "score": null
    }
  ];
  const [dashboardStats, setDashboardStats] = useState(null);

  useEffect(() => {

    let userId = currentUser.uid;
    if(localStorage.getItem("user_role") == "hr"){
      if(user_id){
        userId = user_id;
      }else{
        userId = currentUser.uid;
      }
    } 
    fetch(baseURLArkajit + `/candidate_flow/get_dashboard_stats?user_id=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.result.stats) {
          setDashboardStats(response.result.stats);
          setPhotoUrl(response.result.photo_url);
          setuserName(response.result.user_name);
        }
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <div className={classes.root}>
      <DashboardAccount photo_url={photo_url} user_name={user_name}/>
      <div className={classes.rightDiv}>
        <DashboardStats dashboardStats={dashboardStats} />
        {/* <LastInterviewStat lastInterviewStat={lastInterviewStat} /> */}
        <InterviewList user_id={user_id?user_id:currentUser.uid} questions={questions} />
      </div>
    </div>
  );
}

export default CandidateDasboard;
