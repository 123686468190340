import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    justifyContent: 'center',
  },
}));

export default useStyles;
