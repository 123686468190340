import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection:"column",
    backgroundColor:"white",
  },
  headerDiv:{
    backgroundColor:"#0f70eb",
    display:"flex",
    alignItems:"center",
    paddingBlock:"1%",
    paddingInline:"1%",
    fontSize:"1.4rem",
    borderTopRightRadius:"5px",
    borderTopLeftRadius:"5px",
  },
  codeIcon:{
    height:"1.8rem",
    width:"1.8rems",
    marginRight:"2%"
  },
  headerText:{
    color:"white"
  },
  interviewDiv:{
    display:"flex",
    paddingTop:"2%",
    paddingInline:"1.5%",
    backgroundColor:"white",
    width:"100%",
    alignItems:"center",
  },
  interviewTitle:{
    color:"#0f70eb",
    fontWeight:"500",
    fontSize:"1.1rem",
  },
  interviewScore:{
    display:"flex",
    justifyContent:"center",
  },
  score:{
    color:"#0f70eb",
    textDecoration: "underline",
    cursor:"pointer",
  },
  startDiv:{
    display:"flex",
    justifyContent:"center",
    color:"#0f70eb",
    cursor:"pointer",
  },
  footerDiv:{
    paddingBlock:"1%",
    backgroundColor:"white"
  },
  interviewDifficulty:{
    display:"flex",
    justifyContent:"center"
  },
  interviewCompany:{
    display:"flex",
    justifyContent:"center"
  },
  resumeCheckbox:{
    display:"flex",
    justifyContent:"center"
  },
}));

export default useStyles;
