import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    fullpage: {
        // fontFamily: "'Manrope', sans-serif",
        height: `96vh`,
        display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(243, 244, 246, 1)',
    },
    starttext:{
        fontFamily: "'Kumbh Sans', sans-serif",
        letterSpacing:'1px',
        color:'#0F70EB',
        fontSize:'22px',
        margin:0,
    },
    heading:{
        letterSpacing:'0.6px',
        wordSpacing:'2px',
        marginTop: '0',
        marginBottom: '40px',
    },
    blob1:{
        pointerEvents:'none',
        opacity:0.7,
        position:'absolute',
        height:'30px',
        width:'30px',
        top:'40vh',
        left:'16vw',

    },
    blob2:{
        pointerEvents:'none',
        opacity:0.5,
        position:'absolute',
        top:'0',
        right:'20vw',
        
    },
    blob3:{
        pointerEvents:'none',
        position:'absolute',
        opacity:0.8,
        height:'30px',
        width:'30px',
        top:'10vh',
        left:'0vw',
    },
    subheading:{
        color:'#a6a6a6',
        fontFamily: "'Kumbh Sans', sans-serif"
    },
    form: {
        zIndex:'2',
        padding:'4%',
        paddingInline:'6%',
        rowGap:'6px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5);',
        borderRadius:'10px',
        // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
    bottom: {
        fontSize:'small',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: "'Kumbh Sans', sans-serif"
    },
    input:{
        '&:focus':{
            border:'none',
            outline: "1px solid black",
        },
        '&::placeholder':{ 
            color:"rgba(192, 192, 192, 1)",
            fontStyle:'normal',
        },
        padding: '8px',
        paddingInline: '14px',
        width:'80%',
        borderRadius: '3px',
        borderColor:'rgba(208, 208, 208, 0.5)',
        borderWidth: '1px',
        fontFamily: "'Kumbh Sans', sans-serif"
    },
    forgotPassword:{
        color: 'rgba(15, 112, 235, 1)',
        width: '80%',
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize:'12px',
        paddingBottom:'8px',
    },
    line:{
        width:'76%',
        height:'12.5px',
        borderBottom: "1px solid #a6a6a6",
        textAlign: 'center',
    },
    span:{
        fontSize:"15px",
        color:'#a6a6a6',
        // backgroundColor: "white",
        backgroundColor: "#f2f2f2",
        padding:"0 10px",
    },
    icon:{
        marginTop:'20px',
    },
    submit:{
        width:'80%',
        marginBottom:'10px',
        padding: '8px',
        borderWidth: '1px',
        borderRadius: '3px',
        borderColor:'transparent',
        backgroundColor:'#0F70EB',
        color:"white",
        fontFamily: "'Kumbh Sans', sans-serif",
    },
}));

export default useStyles;
