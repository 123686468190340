import React, { useEffect, useState } from 'react';
import useStyles from './useStyles';
import LoadingData from 'components/LoadingData';

const DashboardStats = ({ dashboardStats }) => {
  const classes = useStyles();
  

  return (
    <div className={classes.root}>
      <div className={classes.statDiv}>
        <div className={classes.statTitle}>Average Score</div>
        <div className={classes.statValue}><LoadingData loaded={dashboardStats!=null} color='white'>{dashboardStats?.average_score ?? 0}/100</LoadingData></div>
      </div>
      <div className={classes.statDiv}>
        <div className={classes.statTitle}>Global Rank</div>
        <div className={classes.statValue}><LoadingData loaded={dashboardStats!=null} color='white'>{dashboardStats?.global_rank ?? 0}</LoadingData></div>
      </div>
      <div className={classes.statDiv}>
        <div className={classes.statTitle}>Attempted Interviews</div>
        <div className={classes.statValue}><LoadingData loaded={dashboardStats!=null} color='white'>{dashboardStats?.number_of_interviews ?? 0}</LoadingData></div>
      </div>
    </div>
  );
}

export default DashboardStats;
