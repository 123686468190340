import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    width:"100%",
    height:"90vh",
    justifyContent:"center",
  },
  loadingText:{
    marginTop:"5%",
    fontSize:"2rem",
    display:"flex",
    alignItems:"center",
  },
  loadingDots: {
    display: 'flex',
    justifyContent: 'center',
    marginTop : theme.spacing(2),
    marginLeft : theme.spacing(2),
  },
  dot: {
    backgroundColor: "#0F70EB",
    borderRadius: '50%',
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: theme.spacing(0, 0.5),
    animation: '$bounce 1s infinite',
  },
  '@keyframes bounce': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1)',
    },
  },
}));

export default useStyles;