import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { InputField } from "../../components/InputField";
import { SocialIcons } from "../../icons/SocialIcons";
import { StyleGraySocialInstagram } from "../../icons/StyleGraySocialInstagram";
import { StyleGraySocialTwitter } from "../../icons/StyleGraySocialTwitter";
import { StyleWhiteSocialDribbble } from "../../icons/StyleWhiteSocialDribbble";
import emailjs from '@emailjs/browser';
import "./style.css";

import logo from "../../icons/logo.png"
import kf1 from "../../icons/kf-1.jpeg"
import kf2 from "../../icons/kf-2.jpeg"
import kf3 from "../../icons/kf-3.jpeg"
import kf4 from "../../icons/kf-4.jpeg"
import kf5 from "../../icons/kf-5.jpeg"
import kf6 from "../../icons/kf-6.jpeg"

import ta1 from "../../icons/ta-1.jpeg"
import ta2 from "../../icons/ta-2.jpeg"
import ta3 from "../../icons/ta-3.jpeg"

import lifeBeforeImg from "../../icons/life-before.png"
import lifeAfterImg from "../../icons/life-after.png"

import tes1 from "../../icons/tes-1.png"


import flowChartImg from "../../icons/flow-chart.png"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MobileNav } from "../../components/MobileNav";
import { KeyFeature } from "../../components/KeyFeature/KeyFeature";
import { TargetAudience } from "../../components/TargetAudience/TargetAudience";
import { Testimonal } from "../../components/Testimonal/Testimonal";


export const Desktop = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // useEffect(() => {
  //   let targetElement = document.getElementById("contactUs");
  //   targetElement.scrollIntoView({ behavior: "instant", block: "start" });
  //   targetElement = document.getElementById("home");
  //   targetElement.scrollIntoView({ behavior: "smooth", block: "end" });
  // }, []);


  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendEmailPromise = (templateParams) => {
    return emailjs.send('service_end70je', 'template_pdb8tsd', templateParams, "KHXSx7W0Mzgs6x_Zl")
  }

  const redirectToLogin = () => {
    window.location.href = "/login";
  }

  const handleWaitlistClick = (event) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || email === "") {
      toast.warn('Please fill Email!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    if (emailRegex.test(email)) {
      var templateParams = {
        from_name: "Interview AI",
        to_name: email,
        to_email: email,
      };
      console.log("Sending email...", templateParams);
      // make an HTTP request to the Cloud Functions endpoint
      toast.promise(sendEmailPromise(templateParams), {
        pending: 'Sending email...',
        success: 'Email sent successfully',
        error: 'Failed to send email',
      }, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
        .then(() => {
          setStatus("Email Sent Successfully");
        });
      window.open("https://calendly.com/the-hiring-company/30min-demo", "_blank");
    } else {
      toast.warn('Please enter a correct email', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      console.log("Invalid email address");
    }

  };

  const handleSubmit = async (event) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name || !email || name === "" || email === "") {
      toast.warn('Please fill all the fields', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    if (emailRegex.test(email)) {
      var templateParams = {
        from_name: "Interview AI",
        to_name: email,
        to_email: email,
      };
      console.log("Sending email...", templateParams);
      // make an HTTP request to the Cloud Functions endpoint
      toast.promise(sendEmailPromise(templateParams), {
        pending: 'Sending email...',
        success: 'Email sent successfully',
        error: 'Failed to send email',
      }, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
        .then(() => {
          setStatus("Email Sent Successfully");
        });

      window.open("https://calendly.com/the-hiring-company/30min-demo", "_blank");

    } else {
      toast.warn('Please enter a correct email', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      console.log("Invalid email address");
    }
  };

  function myFunction() {
    var x = document.getElementById("nav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }


  const handleLinkClick = (event, targetId) => {
    event.preventDefault();

    const targetElement = document.getElementById(targetId);
    targetElement.scrollIntoView({ behavior: "smooth", block: targetId == "home" ? "end" : "start" });

  };

  const kfs = [
    {
      text: "FAANG Interview Questions",
      img: kf6
    },
    {
      text: "Try DS Algo Interviews for Free!",
      img: kf1
    },
    {
      text: "Structured Candidate Feedback",
      img: kf2
    },
    {
      text: "Coding Round Interview",
      img: kf3
    },
    {
      text: "System Design Interview",
      img: kf4
    },
    {
      text: "Behavioral Interview",
      img: kf5
    }
  ]

  const tas = [
    {
      heading: "Staffing Companies",
      text: "Lorem ipsom dummy text again make sure that this text does not exceed 75 characters ",
      img: ta1
    },
    {
      heading: "Individual Recruiters",
      text: "Lorem ipsom dummy text again make sure that this text does not exceed 75 characters ",
      img: ta2
    },
    {
      heading: "Businesses and Startups",
      text: "Lorem ipsom dummy text again make sure that this text does not exceed 75 characters ",
      img: ta3
    }
  ]
  const tes = [
    {
      testimonal: "Testimonials Dummy Text . Make sure this text does not exceed 50-75 characters at all costs.",
      img: tes1,
      heading: "John Doe",
      subheading: "CEO, ABC Company"
    },
    {
      testimonal: "Testimonials Dummy Text . Make sure this text does not exceed 50-75 characters at all costs.",
      img: tes1,
      heading: "John Doe",
      subheading: "CEO, ABC Company"
    },
    {
      testimonal: "Testimonials Dummy Text . Make sure this text does not exceed 50-75 characters at all costs.",
      img: tes1,
      heading: "John Doe",
      subheading: "CEO, ABC Company"
    },
    {
      testimonal: "Testimonials Dummy Text . Make sure this text does not exceed 50-75 characters at all costs.",
      img: tes1,
      heading: "John Doe",
      subheading: "CEO, ABC Company"
    },
    {
      testimonal: "Testimonials Dummy Text . Make sure this text does not exceed 50-75 characters at all costs.",
      img: tes1,
      heading: "John Doe",
      subheading: "CEO, ABC Company"
    }
  ]

  return (
    <div className="desktop">
      <ToastContainer />
      <div className="div" >
        <div className="overlap-group">
          <div className="rectangle" />
          {/* <div className="ellipse" /> */}
          <div className="body">
            <div className="hero-section" id="home">
              <div className="hero-section-2">
                <div className="frame">
                  <p className="text-wrapper">Your AI Interviewer. Practice mock interviews. Land your dream job.</p>
                  <div className="frame-2">
                    {/* <p className="text-wrapper-3">
                      Say goodbye to lengthy interviews and costly hiring mistakes. Let technology do the heavy lifting, so you can
                      focus on building a harmonious, high-performing team.
                    </p> */}
                    <div className="section1-font" >
                      <div className="section1-subheading"><p style={{ color: "#0e70eb",marginRight:"8px" }}>For</p> Business</div>
                      Say goodbye to lengthy interviews and costly hiring mistakes. Let technology do the heavy lifting, so you can focus on building a harmonious, high-performing team.
                      <div className="tempButtonDiv">
                        <Button disabled className="button-instance" size="normal" text="Coming Soon" type="primary" handleClick={()=>{}}/>
                      <Button disabled className="button-instance-mobile" size="normal" text="Coming Soon" type="primary" handleClick={()=>{}}/>
                      </div>
                    </div>
                    <div className="section1-font">
                      <div className="section1-subheading"><p style={{ color: "#0e70eb", marginRight:"5px"}}>For</p> Developers</div>
                      Ace your next interview with AI-powered mock sessions tailored to real industry trends. Receive instant feedback and equip yourself with the insights to land your dream job.
                      <div className="tempButtonDiv">
                      <Button className="button-instance" size="normal" text="Try DS Algo Interviews for Free!" type="primary" handleClick={redirectToLogin} />
                      <Button className="button-instance-mobile" size="normal" text="Try Now!" type="primary" handleClick={redirectToLogin} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="image"> */}
                <img
                  className="img"
                  alt="Hero section"
                  style={{borderRadius:"20px"}}
                  src="https://c.animaapp.com/pk5fxSEx/img/hero-section-1.png"
                />
                {/* </div> */}
              </div>
              <div className="waitlist">
                <div className="frame-5">
                  <InputField className="input-field-instance" size="primary" placeholder="Your Email" onChange={handleEmailChange} />
                  <Button className="button-instance" size="normal" text="Schedule Demo" type="primary" handleClick={handleWaitlistClick} />
                  <Button className="button-instance-mobile" size="normal" text="Schedule Demo" type="primary" handleClick={handleWaitlistClick} />
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-4">Try thehiringcompany for free for your first job post. No credit card required. </div>
                </div>
              </div>
            </div>
            {/* <div className="SD-for-businesses" id="aboutUs">
              <div className="div-2">
                <div className="frame-7">
                  <div className="frame-4">
                    <div className="text-wrapper-5">For Business</div>
                    <div className="text-wrapper-6">Automate your hiring interviews</div>
                  </div>
                  <div className="steps">
                    <div className="text-wrapper-7">1. Upload your candidate list</div>
                    <p className="text-wrapper-8">2. AI conducts interviews on scale</p>
                    <p className="text-wrapper-9">3. AI analyzes the candidates and creates feedback reports</p>
                    <p className="text-wrapper-8">4. You select the topmost candidate</p>
                  </div>
                </div>
                <Button className="button-instance" size="normal" text="Try for your business" type="primary" href="contactUs" />
              </div>
              <div className="for-business-wrapper">
                <img
                  className="for-business"
                  alt="For business"
                  src="https://c.animaapp.com/pk5fxSEx/img/for-business-1.png"
                />
              </div>
            </div> */}
            <div className="subsection">
              <div className="section-heading">Our AI Interviewer is Powered With</div>
              <div className="key-features-items">
                {
                  kfs.map((kf, index) => <KeyFeature key={index} text={kf.text} img={kf.img} />)
                }
              </div>
              <div className="waitlist" style={{ marginTop: "5%" }}>
                <div className="frame-5">
                  <InputField className="input-field-instance" size="primary" placeholder="Your Email" onChange={handleEmailChange} />
                  <Button className="button-instance" size="normal" text="Schedule Demo" type="primary" handleClick={handleWaitlistClick} />
                  <Button className="button-instance-mobile" size="normal" text="Schedule Demo" type="primary" handleClick={handleWaitlistClick} />                </div>
                <div className="frame-6">
                  <div className="text-wrapper-4">Try thehiringcompany for free for your first job post. No credit card required. </div>
                  {/* <div className="text-wrapper-4">No credit card required</div>
                  <p className="text-wrapper-4">Sign up to stay updated</p> */}
                </div>
              </div>
            </div>
            <div className="subsection">
              <div className="section-heading">How it Works</div>
              <div className="flow-chart" id="aboutUs">
                <img class="flow-chart-img" src={flowChartImg}></img>
              </div>
            </div>
            {/* <div className="SD-for-candidates">
              <div className="for-condidates-wrapper">
                <img
                  className="for-business"
                  alt="For condidates"
                  src="https://c.animaapp.com/pk5fxSEx/img/for-condidates-2-1.png"
                />
              </div>
              <div className="frame-8">
                <div className="frame-7">
                  <div className="frame-4">
                    <div className="text-wrapper-5">For Developers</div>
                    <div className="text-wrapper-6">AI-Powered Interview Practice</div>
                  </div>
                  <div className="steps">
                    <p className="text-wrapper-7">1. Select what you want to practice DS, Coding, System Design etc.</p>
                    <p className="text-wrapper-8">2. AI conducts your mock interview</p>
                    <div className="text-wrapper-10">3. You get instant feedback</div>
                  </div>
                </div>
              </div>
            </div> */}
            
            {/* <div className="target-audience subsection">
              <div className="section-heading">Target Audience</div>
              <div className="key-features-items">
                {
                  tas.map((ta, index) => <TargetAudience key={index} heading={ta.heading} text={ta.text} img={ta.img} />)
                }
              </div>
              <div className="waitlist">
                <div className="frame-5">
                  <InputField className="input-field-instance" size="primary" placeholder="Your Email" onChange={handleEmailChange} />
                  <Button className="button-instance" size="normal" text="Schedule Demo" type="primary" handleClick={handleWaitlistClick} />
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-4">Try thehiringcompany for free for your first job post. No credit card required. </div>
                </div>
              </div>
            </div> */}
            {/* <div className="subsection">
              <div className="section-heading">How Life is Better With thehiringcompany</div>
              <div className="life-before-after">
                <div className="life-before">
                  <img src={lifeBeforeImg} style={{
                    height: "60%",
                    width: "60%",
                    borderRadius: "20px",
                  }}></img>
                  <div className="before-us-heading">Without Us</div>
                  <div className="before-us-text">Lorem ipsom text, make sure not to exceed 75 charactrers again!</div>
                </div>
                <div className="life-before">
                  <img src={lifeAfterImg} style={{
                    height: "60%",
                    width: "60%",
                    borderRadius: "20px",
                  }}></img>
                  <div className="before-us-heading">After Us</div>
                  <div className="before-us-text">Lorem ipsom text, make sure not to exceed 75 charactrers again!</div>
                </div>
              </div>

              <div className="waitlist">
                <div className="frame-5">
                  <InputField className="input-field-instance" size="primary" placeholder="Your Email" onChange={handleEmailChange} />
                  <Button className="button-instance" size="normal" text="Schedule Demo" type="primary" handleClick={handleWaitlistClick} />
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-4">Try thehiringcompany for free for your first job post. No credit card required. </div>
                </div>
              </div>
            </div> */}
            {/* <div className="subsection testimonal">
              <div className="section-heading">Testimonals</div>
              <div className="testimonal-items">
                {
                  tes.map((te, index) => <Testimonal  key={index} heading={te.heading} subHeading={te.subheading} img={te.img} testimonal={te.testimonal} />)
                }
              </div>
            </div> */}
            <div className="contact-us" id="contactUs">
              <div className="frame-9">
                <div className="frame-10">
                  <div className="text-wrapper-5">Contact Us</div>
                  <div className="text-wrapper-11">Let’s connect sometime</div>
                </div>
                <div className="frame-11">
                  <div className="frame-12" style={{ gap: "24px" }}>
                    <InputField
                      className="input-field-instance"
                      size="primary"
                      text="Name"
                      value={name}
                      placeholder={"Name"}
                      onChange={handleNameChange}
                    />
                    <InputField
                      className="input-field-instance"
                      size="primary"
                      text="Email"
                      value={email}
                      placeholder={"Email"}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <InputField
                    className="design-component-instance-node-2"
                    divClassName="design-component-instance-node"
                    size="large"
                    textarea
                    value={message}
                    placeholder="Short Message"
                    onChange={handleMessageChange}
                  />
                  <Button className="design-component-instance-node-2" size="normal" text="Schedule Demo" type="primary" handleClick={handleSubmit} />
                </div>
              </div>
            </div>
            <footer className="footer">
              <div className="frame-12 footer-12" >
                <div className="company-info">
                  <div className="div-3">
                    <img src={logo} height={"5%"} width={"5%"} />
                    <div className="frame-13">
                      <div className="text-wrapper-12">thehiringcompany</div>
                      <div className="text-wrapper-13">.ai</div>
                    </div>
                  </div>
                  <p className="text-wrapper-14">
                    The Hiring Company helps business automate their hiring interviews and provide evaluation instantaneously.
                  </p>
                </div>
                {/* <div className="links"> */}
                <div className="col">
                  <div className="text-wrapper-18">Contact Us</div>
                  <div className="frame-14">
                    <div className="list-items-2">
                      <div className="frame-15">
                        <div className="text-wrapper-19">Email :</div>
                        <div className="text-wrapper-20">shamith2709@gmail.com</div>
                      </div>
                      <div className="frame-16">
                        <div className="text-wrapper-19">Phone :</div>
                        <div className="text-wrapper-20">+91 81971 00734</div>
                      </div>
                    </div>
                    <div className="div-3">
                      <StyleGraySocialInstagram className="social-icons" color="#13131A" fill="#13131A" />
                      <StyleWhiteSocialDribbble
                        className="social-icons"
                        color="#13131A"
                        fill="#13131A"
                        opacity="0.1"
                      />
                      <StyleGraySocialTwitter className="social-icons" color="#13131A" fill="#13131A" opacity="0.1" />
                      <SocialIcons className="social-icons" color="#13131A" fill="#13131A" opacity="0.1" />
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
              <img className="line" alt="Line" src="https://c.animaapp.com/pk5fxSEx/img/line-1.svg" />
              <div className="copyright">
                <p className="text-wrapper-19" style={{ fontSize: "10px" }}>Copyright © 2023 thehiringcompany. All rights reserved</p>
                <div className="frame-17">
                  <div className="text-wrapper-22">Private Policy</div>
                  <div className="text-wrapper-23">|</div>
                  <div className="text-wrapper-22">Terms &amp; Conditions</div>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <div className="nav" id="nav">
          <div className="div-3">
            <img src={logo} className="nav-logo" />
            <div className="frame-13">
              <div className="text-wrapper-24">thehiringcompany</div>
              <div className="text-wrapper-25">.ai</div>
            </div>
          </div>
          <div className="frame-18">
          <Button className="button-instance" size="normal" text="Try FAANG Interviews for Free!" type="primary" handleClick={redirectToLogin} />
            <div className="text-wrapper-26" ><a href="#home" onClick={(event) => handleLinkClick(event, "home")}>Home</a></div>
            <div className="text-wrapper-26" ><a href="#aboutUs" onClick={(event) => handleLinkClick(event, "aboutUs")}>About Us</a></div>
            <div className="text-wrapper-26" ><a href="#contactUs" onClick={(event) => handleLinkClick(event, "contactUs")}>Contact</a></div>
          </div>
          <MobileNav />
        </div>
      </div>
    </div>
  );
};
