import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%',
  },
  title: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px',
    height: '50px',
    borderRadius: '25px',
    backgroundColor: '#4caf50',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    marginTop: "2%",
  },
  input: {
    display: 'none',
  },
  playButtonContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: '#4caf50',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
  },
  playButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
  playIcon: {
    fontSize: '24px',
    color: '#4caf50',
  },
  transcript: {
    width: '100%',
    minHeight: '50px',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    cursor: 'text',
    display:"flex",
    padding: "5px",
    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    '&.editable': {
      boxShadow: '0 0 4px rgba(76, 175, 80, 0.4)',
      cursor: 'auto',
    },
    '&.recording': {
      boxShadow: '0 0 8px rgba(76, 175, 80, 0.8)',
      animation: '$pulse 1s infinite',
    },
    justifyContent:"space-between",
   alignItems:"center",
  },
  userMessageContainer:{
    display: 'flex',
    alignSelf: 'flex-end',
    marginBlock: "10px",
  },
  botMessageContainer:{
    display: 'flex',
    alignSelf: 'flex-start',
  },
  aiVoiceIcon:{
    backgroundColor:"#DBEAFC",
    borderRadius:"50%",
    width:"40px",
    height:"40px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginRight:"10px",
    flexShrink: 0,
  },
  userIcon:{
    backgroundColor:"#DBEAFC",
    borderRadius:"50%",
    width:"40px",
    height:"40px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginLeft:"10px",
    flexShrink: 0,
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    alignItems: "center",
    borderRadius: '4px',
    overflowY: 'auto',
    width: "100%",
    height: "80vh"
  },
  userMessage: {
    backgroundColor: '#0F70EB',
    color: '#fff',
    padding: theme.spacing(1),
    borderRadius: '4px',
    wordWrap: 'break-word',
    marginBottom: "2%"
  },
  botMessage: {
    backgroundColor: '#DBEAFC',
    padding: theme.spacing(1),
    borderRadius: '4px',
    wordWrap: 'break-word',
    marginBottom: "2%",
  },
  inputSection: {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    bottom: "0.5%",
    // right:"0.5%",
    alignSelf: "center",
  },
  micButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: '2.5%',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#4caf50',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#388e3c',
    },
    position: 'relative',
    overflow: 'hidden',
  },
  wave: {
    animation: '$wave 1s ease-in-out infinite',
  },
  '@keyframes wave': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  buttons:{
    display:"flex",
    width:"6.5%",
    justifyContent:"space-between",
    marginInline:"1%",
  },
  inputField:{
    border:"0px",
    outline: 0,
    width:"100%",
    fontSize:"1rem",
    resize:"none",
    backgroundColor:"transparent",
  },
  linearLoader:{
    height:"5px",
    width:"100%",
  },
  linearLoaderRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    overflow: 'hidden',
  },
}));

export default useStyles;
